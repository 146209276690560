import { CircleChartSize } from './CircleChartSize';

const getSmallBoxSize = () => 12;
const getMediumBoxSize = () => 18;

const getSmallLineWidth = () => 14;
const getMediumLineWidth = () => 14;

const getSmallFontSize = () => 22;
const getMediumFontSize = () => 40;

export const getBoxSizeByCircleChartSize = (circleChartSize: CircleChartSize): number => {
    switch (circleChartSize) {
        case CircleChartSize.small:
            return getSmallBoxSize();
        case CircleChartSize.medium:
            return getMediumBoxSize();
        default:
            throw new Error('Unexpected CircleChartSize in getBoxSizeByCircleChartSize');
    }
};

export const getLineWidthByCircleChartSize = (circleChartSize: CircleChartSize): number => {
    switch (circleChartSize) {
        case CircleChartSize.small:
            return getSmallLineWidth();
        case CircleChartSize.medium:
            return getMediumLineWidth();
        default:
            throw new Error('Unexpected CircleChartSize in getLineWidthByCircleChartSize');
    }
};

export const getFontSizeByCircleChartSize = (circleChartSize: CircleChartSize): number => {
    switch (circleChartSize) {
        case CircleChartSize.small:
            return getSmallFontSize();
        case CircleChartSize.medium:
            return getMediumFontSize();
        default:
            throw new Error('Unexpected CircleChartSize in getFontSizeByCircleChartSize');
    }
};
