import { CategoryHeaderAuditsCountsProps } from './categoryHeaderAuditsCountsProps';
import { GlobalThemeType } from '../../../../../../../theme/globalTheme';
import { prismNonNegativeInteger } from 'newtype-ts/lib/NonNegativeInteger';
import { ResultPictogramTextType } from '../../../../../../pictogram/resultWithText/ResultPictogramTextType';
import { ResultType } from '../../../../../../pictogram/result/ResultType';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ResultPictogramWithText from '../../../../../../pictogram/resultWithText';
import styled from 'styled-components';

const BoxWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const ErrorBox = styled.div``;

interface WarningBoxProps {
    readonly theme: GlobalThemeType;
}

const WarningBox = styled.div`
    margin-left: ${(props: WarningBoxProps) => {
        return props.theme.spacing(8);
    }}px;
`;
interface PassedBoxProps {
    readonly theme: GlobalThemeType;
}

const PassedBox = styled.div`
    margin-left: ${(props: PassedBoxProps) => {
        return props.theme.spacing(8);
    }}px;
`;

const CategoryHeaderAuditsCounts: React.FC<CategoryHeaderAuditsCountsProps> = (props) => {
    const { counts } = props;

    const { t } = useTranslation();
    const theme = useTheme<GlobalThemeType>();

    const errorsText = t('pages.result.category.header.errors', { count: prismNonNegativeInteger.reverseGet(counts.error) });
    const warningText = t('pages.result.category.header.warning', { count: prismNonNegativeInteger.reverseGet(counts.warning) });
    const passedText = t('pages.result.category.header.passed', { count: prismNonNegativeInteger.reverseGet(counts.passed) });

    return (
        <BoxWrap>
            <ErrorBox>
                <ResultPictogramWithText text={errorsText} textType={ResultPictogramTextType.normal} type={ResultType.error} />
            </ErrorBox>
            <WarningBox theme={theme}>
                <ResultPictogramWithText text={warningText} textType={ResultPictogramTextType.normal} type={ResultType.warning} />
            </WarningBox>
            <PassedBox theme={theme}>
                <ResultPictogramWithText text={passedText} textType={ResultPictogramTextType.normal} type={ResultType.passed} />
            </PassedBox>
        </BoxWrap>
    );
};

export default CategoryHeaderAuditsCounts;
