import { DefaultPageSelector } from './selector';
import { UrlSource } from './state';

export const urlConfirmed = (url: string, urlSource: UrlSource) => {
    return {
        type: DefaultPageSelector.urlConfirmed,
        url,
        urlSource,
    } as const;
};
export type UrlConfirmedAction = ReturnType<typeof urlConfirmed>;

export type DefaultPageAction = UrlConfirmedAction;
