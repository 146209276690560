import { AppState } from '../../../../appState';
import { getSavedAuditRequestResult } from './getSavedAuditRequestResult';
import { SavedAuditRequestResult } from '../../../state';
import { useSelector } from 'react-redux';

const useSavedAuditRequestResult = (): SavedAuditRequestResult | undefined => {
    const savedAuditRequestResult = useSelector<AppState, SavedAuditRequestResult | undefined>((state) => {
        return getSavedAuditRequestResult(state);
    });

    return savedAuditRequestResult;
};

export default useSavedAuditRequestResult;
