import { ajax } from 'rxjs/ajax';
import { ApiError, generalErrors } from '../../api/api';
import { ApiResponseErrorSchema, auditGetErrorCodes, auditGetResultSchema, endpointAuditGet } from '../../api/endpointAuditGet';
import { AppAction } from '../appAction';
import { AppState } from '../appState';
import { auditGetDelayRetry, auditGetMaxRetry } from './config/retrySettings';
import { auditPostResultSchema, endpointAuditPost } from '../../api/endpointAuditPost';
import { auditRoute } from '../../route/route';
import { AuditSelector } from './selector';
import { AuditStatus } from '../../model/audit/auditStatus';
import { catchError, delay, delayWhen, map, mergeMap, retryWhen, take, takeUntil } from 'rxjs/internal/operators';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { DefaultPageSelector } from '../form/selector';
import { EpicDependencies } from '../epicDependencies';
import { getJsonContentType } from '../../utils/ajax/getJsonContentType';
import { isLeft } from 'fp-ts/Either';
import {
    loadingAuditResultWasSet,
    LoadingAuditResultWasSetAction,
    loadingAuditResultWasUnset,
    LoadingAuditResultWasUnsetAction,
    progressFinish,
    ProgressFinishAction,
    progressStart,
    ProgressStartAction,
    sendingRequestsForResultCreatedAuditStarted,
    SendingRequestsForResultCreatedAuditStartedAction,
    sendingRequestsForResultCreatedAuditTimeHasExpired,
    SendingRequestsForResultCreatedAuditTimeHasExpiredAction,
    sendingRequestsForResultCreatedAuditUrlHttpCodeWasForbidden,
    SendingRequestsForResultCreatedAuditUrlHttpCodeWasForbiddenAction,
    sendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorized,
    SendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorizedAction,
    sendingRequestsForResultCreatedAuditWasFailed,
    SendingRequestsForResultCreatedAuditWasFailedAction,
    sendingRequestsForResultCreatedWasAuditSuccessful,
    SendingRequestsForResultCreatedWasAuditSuccessfulAction,
    sendingRequestToAnalyzeStarted,
    SendingRequestToAnalyzeStartedAction,
    sendingRequestToAnalyzeWasFailed,
    SendingRequestToAnalyzeWasFailedAction,
    sendingRequestToAnalyzeWasSuccessful,
    SendingRequestToAnalyzeWasSuccessfulAction,
} from './action';
import { timer } from 'rxjs';
import { UrlConfirmedAction } from '../form/action';
import { UrlSource } from '../form/state';
import { UUID } from 'io-ts-types/lib/UUID';

const errorMessageForRetryGetAudit = 'retry due to pending';
const errorMessageMaxRetry = 'max retry';
const errorMessageForbidden = 'forbidden';
const errorMessageUnauthorized = 'Unauthorized';

const urlConfirmedMapToSendingRequestToAnalyzeStartedEpic: Epic<
    UrlConfirmedAction,
    // @ts-ignore
    SendingRequestToAnalyzeStartedAction,
    AppState,
    EpicDependencies
> = (action$) => {
    return action$.pipe(
        ofType(DefaultPageSelector.urlConfirmed),
        mergeMap((action) => {
            const { url } = action;

            return [sendingRequestToAnalyzeStarted(url)];
        }),
    );
};

const urlConfirmedMapToSendingRequestToRedirectEpic: Epic<
    UrlConfirmedAction,
    // @ts-ignore
    SendingRequestToAnalyzeStartedAction,
    AppState,
    EpicDependencies
> = (action$, state$, { history }) => {
    return action$.pipe(
        ofType(DefaultPageSelector.urlConfirmed),
        delay(100), // due to url in form in header
        mergeMap((action) => {
            const { urlSource } = action;
            if (urlSource === UrlSource.defaultPage || urlSource === UrlSource.headerSavedAudit) {
                history.push(auditRoute.url());
            }

            return [];
        }),
    );
};

const urlConfirmedMapToProgressStartEpic: Epic<
    UrlConfirmedAction,
    // @ts-ignore
    ProgressStartAction,
    AppState,
    EpicDependencies
> = (action$) => {
    return action$.pipe(
        ofType(DefaultPageSelector.urlConfirmed),
        mergeMap(() => {
            return [progressStart()];
        }),
    );
};

const urlConfirmedMapToLoadingAuditResultWasSetEpic: Epic<
    UrlConfirmedAction,
    // @ts-ignore
    LoadingAuditResultWasSetAction,
    AppState,
    EpicDependencies
> = (action$) => {
    return action$.pipe(
        ofType(DefaultPageSelector.urlConfirmed),
        mergeMap(() => {
            return [loadingAuditResultWasSet()];
        }),
    );
};

const sendingRequestToAnalyzeStartedMapToSendingRequestToAnalyzeWasFailedOrSendingRequestToAnalyzeWasSuccessfulEpic: Epic<
    SendingRequestToAnalyzeStartedAction,
    // @ts-ignore
    SendingRequestToAnalyzeWasFailedAction | SendingRequestToAnalyzeWasSuccessfulAction,
    AppState,
    EpicDependencies
> = (action$) => {
    return action$.pipe(
        ofType(AuditSelector.sendingRequestToAnalyzeStarted),
        mergeMap((action) => {
            const { url } = action;

            const body = JSON.stringify({
                url,
            });

            return ajax.post(endpointAuditPost(), body, getJsonContentType()).pipe(
                map((response) => {
                    if (response.response.success !== true) {
                        return sendingRequestToAnalyzeWasFailed(generalErrors.failed);
                    }

                    const validationResult = auditPostResultSchema.safeParse(response.response.data);
                    if (validationResult.success !== true) {
                        return sendingRequestToAnalyzeWasFailed(generalErrors.failed);
                    }

                    const optionAuditUuid = UUID.decode(validationResult.data.uuid);
                    if (isLeft(optionAuditUuid)) {
                        return sendingRequestToAnalyzeWasFailed(generalErrors.failed);
                    }
                    const auditUuid = optionAuditUuid.right;

                    return sendingRequestToAnalyzeWasSuccessful(auditUuid);
                }),
                catchError(() => {
                    return [sendingRequestToAnalyzeWasFailed(generalErrors.failed)];
                }),
            );
        }),
    );
};

const sendingRequestToAnalyzeWasSuccessfulMapToSendingRequestsForResultCreatedAuditStartedEpic: Epic<
    SendingRequestToAnalyzeWasSuccessfulAction,
    // @ts-ignore
    SendingRequestsForResultCreatedAuditStartedAction,
    AppState,
    EpicDependencies
> = (action$) => {
    return action$.pipe(
        ofType(AuditSelector.sendingRequestToAnalyzeWasSuccessful),
        mergeMap((action) => {
            const { auditUuid } = action;
            return [sendingRequestsForResultCreatedAuditStarted(auditUuid)];
        }),
    );
};

const sendingRequestToAnalyzeWasSuccessfulMapToSendingRequestsForResultCreatedAuditTimeHasExpiredOrSendingRequestsForResultCreatedAuditWasFailedOrSendingRequestsForResultCreatedWasAuditSuccessfulEpic: Epic<
    SendingRequestsForResultCreatedAuditStartedAction | SendingRequestToAnalyzeStartedAction,
    // @ts-ignore
    | SendingRequestsForResultCreatedAuditTimeHasExpiredAction
    | SendingRequestsForResultCreatedAuditWasFailedAction
    | SendingRequestsForResultCreatedWasAuditSuccessfulAction
    | SendingRequestsForResultCreatedAuditUrlHttpCodeWasForbiddenAction
    | SendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorizedAction,
    AppState,
    EpicDependencies
> = (action$) => {
    const stopPolling$ = action$.pipe(ofType(AuditSelector.sendingRequestToAnalyzeStarted));

    return action$.pipe(
        ofType(AuditSelector.sendingRequestsForResultCreatedAuditStarted),
        mergeMap((action) => {
            if (action.type !== AuditSelector.sendingRequestsForResultCreatedAuditStarted) {
                return [];
            }
            const { auditUuid } = action;

            return ajax.get(endpointAuditGet(auditUuid)).pipe(
                delay(auditGetDelayRetry),
                map((response) => {
                    const validationResult = auditGetResultSchema.safeParse(response.response.data);

                    if (validationResult.success !== true) {
                        return sendingRequestsForResultCreatedAuditWasFailed();
                    }

                    const data = validationResult.data;

                    switch (data.status) {
                        case AuditStatus.forbidden:
                            throw new Error(errorMessageForbidden);
                        case AuditStatus.unauthorized:
                            throw new Error(errorMessageUnauthorized);
                    }
                    if (data.status !== AuditStatus.done) {
                        throw new Error(errorMessageForRetryGetAudit);
                    }

                    const optionAuditUuid = UUID.decode(validationResult.data.uuid);
                    if (isLeft(optionAuditUuid)) {
                        return sendingRequestsForResultCreatedAuditWasFailed();
                    }
                    const auditUuid = optionAuditUuid.right;

                    if (data.url === undefined || data.finishedAt === undefined || data.report === undefined) {
                        return sendingRequestsForResultCreatedAuditWasFailed();
                    }

                    return sendingRequestsForResultCreatedWasAuditSuccessful(auditUuid, data.url, validationResult.data.isReachable === true, data.finishedAt, data.report);
                }),
                retryWhen((errors) => {
                    return errors.pipe(
                        takeUntil(stopPolling$),
                        take(auditGetMaxRetry),
                        delayWhen((val, index) => {
                            if (index === auditGetMaxRetry - 1) {
                                throw Error(errorMessageMaxRetry);
                            }
                            if (val.message === errorMessageForRetryGetAudit) {
                                return timer(1);
                            }
                            throw val;
                        }),
                    );
                }),
                catchError((error) => {
                    if (error.response) {
                        const parsedResponse = ApiResponseErrorSchema.safeParse(error.response);
                        if (!parsedResponse.success) {
                            return [sendingRequestsForResultCreatedAuditWasFailed(generalErrors.failed)];
                        }

                        const data = parsedResponse.data;

                        let errorType: ApiError = generalErrors.failed;
                        data.errors.forEach((error) => {
                            if (Object.keys(auditGetErrorCodes).indexOf(error.errorCode) >= 0) {
                                errorType = auditGetErrorCodes[error.errorCode];
                            }
                        });
                        return [sendingRequestsForResultCreatedAuditWasFailed(errorType)];
                    }
                    if (error.message === errorMessageMaxRetry) {
                        return [sendingRequestsForResultCreatedAuditTimeHasExpired()];
                    }
                    if (error.message === errorMessageForbidden) {
                        return [sendingRequestsForResultCreatedAuditUrlHttpCodeWasForbidden()];
                    }
                    if (error.message === errorMessageUnauthorized) {
                        return [sendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorized()];
                    }

                    return [sendingRequestsForResultCreatedAuditWasFailed()];
                }),
            );
        }),
    );
};

const sendingSendingRequestsForResultCreatedWasAuditSuccessfulOrSendingRequestsForResultCreatedAuditWasFailedOrSendingRequestsForResultCreatedAuditTimeHasExpiredMapToRedirectEpic: Epic<
    | SendingRequestsForResultCreatedWasAuditSuccessfulAction
    | SendingRequestsForResultCreatedAuditWasFailedAction
    | SendingRequestsForResultCreatedAuditTimeHasExpiredAction
    | SendingRequestsForResultCreatedAuditUrlHttpCodeWasForbiddenAction
    | SendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorizedAction,
    // @ts-ignore
    void,
    AppState,
    EpicDependencies
> = (action$, state$, { history }) => {
    return action$.pipe(
        ofType(
            AuditSelector.sendingRequestsForResultCreatedWasAuditSuccessful,
            AuditSelector.sendingRequestsForResultCreatedAuditWasFailed,
            AuditSelector.sendingRequestsForResultCreatedAuditTimeHasExpired,
            AuditSelector.sendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorized,
            AuditSelector.sendingRequestsForResultCreatedAuditUrlHttpCodeWasForbidden,
        ),

        mergeMap(() => {
            history.push(auditRoute.url());
            return [];
        }),
    );
};

const sendingSendingRequestsForResultCreatedWasAuditSuccessfulOrSendingRequestsForResultCreatedAuditWasFailedOrSendingRequestsForResultCreatedAuditTimeHasExpiredOrSendingRequestToAnalyzeWasFailedMapToLoadingAuditResultWasUnsetEpic: Epic<
    | SendingRequestsForResultCreatedWasAuditSuccessfulAction
    | SendingRequestsForResultCreatedAuditWasFailedAction
    | SendingRequestsForResultCreatedAuditTimeHasExpiredAction
    | SendingRequestsForResultCreatedAuditUrlHttpCodeWasForbiddenAction
    | SendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorizedAction
    | SendingRequestToAnalyzeWasFailedAction,
    // @ts-ignore
    LoadingAuditResultWasUnsetAction,
    AppState,
    EpicDependencies
> = (action$) => {
    return action$.pipe(
        ofType(
            AuditSelector.sendingRequestsForResultCreatedWasAuditSuccessful,
            AuditSelector.sendingRequestsForResultCreatedAuditWasFailed,
            AuditSelector.sendingRequestsForResultCreatedAuditTimeHasExpired,
            AuditSelector.sendingRequestToAnalyzeWasFailed,
            AuditSelector.sendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorized,
            AuditSelector.sendingRequestsForResultCreatedAuditUrlHttpCodeWasForbidden,
        ),

        mergeMap(() => {
            return [loadingAuditResultWasUnset()];
        }),
    );
};

const sendingSendingRequestsForResultCreatedWasAuditSuccessfulOrSendingRequestsForResultCreatedAuditWasFailedOrSendingRequestsForResultCreatedAuditTimeHasExpiredOrSendingRequestToAnalyzeWasFailedMapToProgressFinishEpic: Epic<
    | SendingRequestsForResultCreatedWasAuditSuccessfulAction
    | SendingRequestsForResultCreatedAuditWasFailedAction
    | SendingRequestsForResultCreatedAuditTimeHasExpiredAction
    | SendingRequestToAnalyzeWasFailedAction
    | SendingRequestsForResultCreatedAuditUrlHttpCodeWasForbiddenAction
    | SendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorizedAction,
    // @ts-ignore
    ProgressFinishAction,
    AppState,
    EpicDependencies
> = (action$) => {
    return action$.pipe(
        ofType(
            AuditSelector.sendingRequestsForResultCreatedWasAuditSuccessful,
            AuditSelector.sendingRequestsForResultCreatedAuditWasFailed,
            AuditSelector.sendingRequestsForResultCreatedAuditTimeHasExpired,
            AuditSelector.sendingRequestToAnalyzeWasFailed,
            AuditSelector.sendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorized,
            AuditSelector.sendingRequestsForResultCreatedAuditUrlHttpCodeWasForbidden,
        ),

        mergeMap(() => {
            return [progressFinish()];
        }),
    );
};

export const auditEpics = combineEpics<AppAction, AppAction, AppState, EpicDependencies>(
    // @ts-ignore
    urlConfirmedMapToSendingRequestToAnalyzeStartedEpic,
    sendingRequestToAnalyzeWasSuccessfulMapToSendingRequestsForResultCreatedAuditTimeHasExpiredOrSendingRequestsForResultCreatedAuditWasFailedOrSendingRequestsForResultCreatedWasAuditSuccessfulEpic,
    urlConfirmedMapToLoadingAuditResultWasSetEpic,
    sendingRequestToAnalyzeStartedMapToSendingRequestToAnalyzeWasFailedOrSendingRequestToAnalyzeWasSuccessfulEpic,
    sendingRequestToAnalyzeWasSuccessfulMapToSendingRequestsForResultCreatedAuditStartedEpic,
    sendingSendingRequestsForResultCreatedWasAuditSuccessfulOrSendingRequestsForResultCreatedAuditWasFailedOrSendingRequestsForResultCreatedAuditTimeHasExpiredMapToRedirectEpic,
    sendingSendingRequestsForResultCreatedWasAuditSuccessfulOrSendingRequestsForResultCreatedAuditWasFailedOrSendingRequestsForResultCreatedAuditTimeHasExpiredOrSendingRequestToAnalyzeWasFailedMapToLoadingAuditResultWasUnsetEpic,
    urlConfirmedMapToSendingRequestToRedirectEpic,
    urlConfirmedMapToProgressStartEpic,
    sendingSendingRequestsForResultCreatedWasAuditSuccessfulOrSendingRequestsForResultCreatedAuditWasFailedOrSendingRequestsForResultCreatedAuditTimeHasExpiredOrSendingRequestToAnalyzeWasFailedMapToProgressFinishEpic,
);
