import { ReportDeviceCategory } from '../../../../api/endpointAuditGet';
import { StickyMenuCategory, StickyMenuCategoryItem } from '../stickyMenu/types/stickyMenuCategory';

export const convertReportDeviceCategoryToStickyMenuCategory = (reportDeviceCategory: ReportDeviceCategory): StickyMenuCategory => {
    return {
        name: reportDeviceCategory.name,
        to: reportDeviceCategory.id,
        items: reportDeviceCategory.categories.map<StickyMenuCategoryItem>((category) => {
            return {
                name: category.name,
                to: `${reportDeviceCategory.id}-${category.id}`,
            };
        }),
    };
};

export const convertReportDeviceCategoriesToStickyMenuCategories = (reportDeviceCategories: ReportDeviceCategory[]): StickyMenuCategory[] => {
    return reportDeviceCategories.map<StickyMenuCategory>((reportDeviceCategory) => {
        return convertReportDeviceCategoryToStickyMenuCategory(reportDeviceCategory);
    });
};
