import { AppState } from '../../../../appState';
import { getErrorLoadingSavedAudit } from './getErrorLoadingSavedAudit';
import { SavedAuditErrorType } from '../../../types/savedAuditErrorType';
import { useSelector } from 'react-redux';

const useErrorLoadingSavedAudit = (): SavedAuditErrorType | null => {
    const errorLoadingSavedAudit = useSelector<AppState, SavedAuditErrorType | null>((state) => {
        return getErrorLoadingSavedAudit(state);
    });

    return errorLoadingSavedAudit;
};

export default useErrorLoadingSavedAudit;
