import './index.css';
import * as Sentry from '@sentry/react';
import { getStore } from './store/configureStore';
import { Integrations } from '@sentry/tracing';
import { MuiThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import App from './App';
import globalTheme from './theme/globalTheme';
import history from './system/history/history';
import i18next from './localization/i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

Sentry.init({
    dsn: 'https://b2cbcdad58e74ac69386e875f38ebec3@sentry.trigama.cz/19',
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
    ],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={getStore(i18next, history)}>
            <Router history={history}>
                <MuiThemeProvider theme={globalTheme}>
                    <App />
                </MuiThemeProvider>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
