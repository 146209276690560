import { Box, Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { GlobalThemeType } from '../../../theme/globalTheme';
import { UrlSource } from '../../../store/form/state';
import { useTranslation } from 'react-i18next';
import { ZonedDateTime } from '@js-joda/core';
import AuditResult from './result';
import FooterDefault from '../../footerDefault';
import Header from '../../header';
import ProgressBar from './progressBar';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import useAuditId from '../../../store/audit/hook/auditRequest/useAuditId/useAuditId';
import useAuditRequestError from '../../../store/audit/hook/auditRequest/useAuditRequestError/useAuditRequestError';
import useAuditRequestResult from '../../../store/audit/hook/auditRequest/useAuditRequestResult/useAuditRequestResult';
import useEnteredUrl from '../../../store/audit/hook/enteredUrl/useEnteredUrl/useEnteredUrl';
import useLoadingAuditResult from '../../../store/audit/hook/auditRequest/useLoadingAuditResult/useLoadingAuditResult';
import useSetUrlConfirmedAction from '../../../store/form/hook/urlConfirmed/useSetUrlConfirmedAction/useSetUrlConfirmedAction';

interface BoxWrapProps {
    readonly theme: GlobalThemeType;
    readonly isSmallLayout: boolean;
}

const BoxWrap = styled.div`
    position: relative;
    padding-bottom: ${(props: BoxWrapProps) => {
        if (props.isSmallLayout) {
            return 0;
        }
        return props.theme.spacing(15);
    }}px;
`;

const AuditPage: React.FC<{}> = () => {
    const { t } = useTranslation();
    const theme = useTheme<GlobalThemeType>();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const result = useAuditRequestResult();
    const auditError = useAuditRequestError();
    const loadingAuditResult = useLoadingAuditResult();
    const enteredUrl = useEnteredUrl();
    const setUrlConfirmed = useSetUrlConfirmedAction();
    const auditId = useAuditId();

    const onEnteredUrl = useCallback(
        (url: string) => {
            setUrlConfirmed(url, UrlSource.headerAudit);
        },
        [setUrlConfirmed],
    );

    return (
        <BoxWrap theme={theme} isSmallLayout={isSmallLayout}>
            <Header defaultUrl={enteredUrl} onEnteredUrl={onEnteredUrl} />
            {loadingAuditResult === true && (
                <Box mt={6} mb={10}>
                    <ProgressBar />
                </Box>
            )}

            {loadingAuditResult === false && result !== undefined && auditId !== undefined && (
                <AuditResult finishedAt={ZonedDateTime.parse(result.finishedAt)} report={result.report} url={result.url} auditUuid={auditId} />
            )}
            {auditError !== null && (
                <Container maxWidth={'md'}>
                    <Box display="flex" justifyContent="center">
                        <Box mt={6} mb={4} width={500}>
                            <Typography variant={'h3'} align="left">
                                Error
                            </Typography>
                            <Box mt={4}>
                                <Typography align="left">{auditError.translation(t)}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            )}
            <FooterDefault />
        </BoxWrap>
    );
};

export default AuditPage;
