import { AppAction } from '../appAction';
import { Reducer } from 'redux';
import { SavedAuditReducerState } from './state';
import { SavedAuditSelector } from './selector';

const getInitialState = (): SavedAuditReducerState => {
    return {
        errorLoadingSavedAudit: null,
        savedAuditRequestResult: undefined,
    };
};

const savedAuditReducer: Reducer<SavedAuditReducerState, AppAction> = (state = getInitialState(), action) => {
    switch (action.type) {
        case SavedAuditSelector.loadingAuditStarted:
            return {
                ...state,
                errorLoadingSavedAudit: null,
            };
        case SavedAuditSelector.loadingAuditWasSuccessful:
            return {
                ...state,
                errorLoadingSavedAudit: null,
                savedAuditRequestResult: action.savedAuditRequestResult,
            };
        case SavedAuditSelector.loadingAuditFailed:
            return {
                ...state,
                savedAuditRequestResult: undefined,
                errorLoadingSavedAudit: action.errorType,
            };
        default:
            return state;
    }
};

export default savedAuditReducer;
