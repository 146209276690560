import { AppState } from '../../../../appState';
import { AuditRequestResult } from '../../../state';
import { getAuditRequestResult } from './getAuditRequestResult';
import { useSelector } from 'react-redux';

const useAuditRequestResult = (): AuditRequestResult | undefined => {
    const result = useSelector<AppState, AuditRequestResult | undefined>((state) => {
        return getAuditRequestResult(state);
    });

    return result;
};

export default useAuditRequestResult;
