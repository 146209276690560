import { CellProps, Column, usePagination, useTable } from 'react-table';
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { TableDetailProps } from './tableDetailProps';
import React, { useRef } from 'react';
import TableDetailLink from './columns/tableDetailLink/index';
import TableDetailNode from './columns/tableDetailNode/index';
import TableDetailSourceLocation from './columns/tableDetailSourceLocation/index';
import TableDetailThumbnail from './columns/tableDetailThumbnail/index';
import TableDetailUrl from './columns/tableDetailUrl/index';

const TableDetail: React.FC<TableDetailProps> = (props) => {
    const { data } = props;
    const ref = useRef<HTMLDivElement>(null);

    const columns: Column[] = React.useMemo(
        () =>
            data.headings.map((column) => {
                const type = column?.itemType || column?.valueType;

                switch (type) {
                    case 'url':
                        return {
                            id: `${column.key}-url`,
                            accessor: column.key,
                            Header: column.text ?? '',
                            Cell: ({ value }: CellProps<{}>) => {
                                return <TableDetailUrl url={value} />;
                            },
                        };
                    case 'thumbnail':
                        return {
                            id: `${column.key}-thumbnail`,
                            accessor: column.key,
                            Header: column.text ?? '',
                            Cell: ({ value }: CellProps<{}>) => <TableDetailThumbnail url={value} />,
                        };
                    case 'numeric':
                        return {
                            id: `${column.key}-numeric`,
                            accessor: column.key,
                            Header: column.text ?? '',
                        };
                    case 'bytes':
                        return {
                            id: `${column.key}-bytes`,
                            accessor: column.key,
                            Header: column.text ?? '',
                            Cell: ({ value }: CellProps<{}>) => `${Math.round(value / 1000)} KiB`,
                        };
                    case 'ms':
                        return {
                            id: `${column.key}-ms`,
                            accessor: column.key,
                            Header: column.text ?? '',
                            Cell: ({ value }: CellProps<{}>) => `${Math.round(value)} ms`,
                        };
                    case 'link':
                        return {
                            id: `${column.key}-link`,
                            accessor: column.key,
                            Header: column.text ?? '',
                            Cell: ({ value }: CellProps<{}>) => <TableDetailLink entity={value} />,
                        };
                    case 'node':
                        return {
                            id: `${column.key}-node`,
                            accessor: column.key,
                            Header: column.text ?? '',
                            Cell: ({ value }: CellProps<{}>) => <TableDetailNode node={value} />,
                        };
                    case 'source-location':
                        return {
                            id: `${column.key}-source-location`,
                            accessor: column.key,
                            Header: column.text ?? '',
                            Cell: ({ value }: CellProps<{}>) => <TableDetailSourceLocation entity={value} />,
                        };
                    case 'text':
                    default:
                        return {
                            id: `${column.key}-text`,
                            accessor: column.key,
                            Header: column.text ?? '',
                        };
                }
            }),
        [data.headings],
    );

    const items = React.useMemo(() => data.items, [data.items]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({ columns, data: items, initialState: { pageSize: 20 } }, usePagination);

    return (
        <Paper>
            <TableContainer ref={ref}>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <TableCell component="th" {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <TableRow {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[20, 50]}
                                count={items.length}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                onChangePage={(event, newPage) => {
                                    if (ref.current !== null) {
                                        ref.current.scrollIntoView();
                                    }

                                    gotoPage(newPage);
                                }}
                                onChangeRowsPerPage={(event) => {
                                    setPageSize(parseInt(event.target.value, 10));
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default TableDetail;
