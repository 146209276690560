import { auditSavedResultRoute } from '../../../../route/route';
import { Button, Typography, useTheme } from '@material-ui/core';
import { GlobalThemeType } from '../../../../theme/globalTheme';
import { ShareResultProps } from './shareResultProps';
import { successMessageHideDelay } from './config';
import { useTranslation } from 'react-i18next';
import _delay from 'lodash/delay';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, { useState } from 'react';
import styled from 'styled-components';

interface BoxWrapProps {
    readonly theme: GlobalThemeType;
}

const BoxWrap = styled.div`
    margin-bottom: ${(props: BoxWrapProps) => props.theme.spacing(2)}px;
    margin-top: ${(props: BoxWrapProps) => props.theme.spacing(2)}px;
    min-height: ${(props: BoxWrapProps) => props.theme.spacing(9)}px;
    text-align: left;
`;

interface SuccessMessageBoxProps {
    readonly theme: GlobalThemeType;
}

const SuccessMessageBox = styled.div`
    margin-top: ${(props: SuccessMessageBoxProps) => props.theme.spacing(1)}px;
`;

const ShareResult: React.FC<ShareResultProps> = (props) => {
    const { auditUuid } = props;

    const { t } = useTranslation();
    const theme = useTheme<GlobalThemeType>();

    const [successMessageIsShowing, setSuccessMessageIsShowing] = useState<boolean>(false);
    const [successMessageShowingId, setSuccessMessageShowingId] = useState<undefined | number>(undefined);

    const onClick = async () => {
        const origin = window.location.origin;

        const url = origin + auditSavedResultRoute.url(auditUuid);

        try {
            await navigator.clipboard.writeText(url);
            setSuccessMessageIsShowing(true);
            if (successMessageShowingId !== undefined) {
                clearTimeout(successMessageShowingId);
            }
            const id = _delay(
                () => {
                    setSuccessMessageIsShowing(false);
                },
                successMessageHideDelay,
                'setSuccessMessageIsShowing',
            );
            setSuccessMessageShowingId(id);
        } catch (e) {
            alert(t('pages.result.overview.copyToClipboardFailed'));
        }
    };

    return (
        <BoxWrap theme={theme}>
            <Button
                color={'primary'}
                variant={'outlined'}
                style={{
                    height: '40px',
                }}
                startIcon={<FileCopyIcon />}
                onClick={onClick}
            >
                {t('pages.result.overview.shareResult')}
            </Button>
            {successMessageIsShowing && (
                <SuccessMessageBox theme={theme}>
                    <Typography>{t('pages.result.overview.shareResultWasSuccessful')}</Typography>
                </SuccessMessageBox>
            )}
        </BoxWrap>
    );
};

export default ShareResult;
