import { Box, CircularProgress, Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { GlobalThemeType } from '../../../theme/globalTheme';
import { isLeft } from 'fp-ts/Either';
import { SavedResultProps } from './savedResultProps';
import { UrlSource } from '../../../store/form/state';
import { useTranslation } from 'react-i18next';
import { UUID } from 'io-ts-types/UUID';
import AuditResult from './result';
import FooterDefault from '../../footerDefault';
import Header from '../../header';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useErrorLoadingSavedAudit from '../../../store/savedAudit/hook/audit/useErrorLoadingSavedAudit/useErrorLoadingSavedAudit';
import useSavedAuditRequestResult from '../../../store/savedAudit/hook/audit/useSavedAuditRequestResult/useSavedAuditRequestResult';
import useSetLoadingAuditStartedAction from '../../../store/savedAudit/hook/audit/useSetLoadingAuditStartedAction/useSetLoadingAuditStartedAction';
import useSetUrlConfirmedAction from '../../../store/form/hook/urlConfirmed/useSetUrlConfirmedAction/useSetUrlConfirmedAction';

interface BoxWrapProps {
    readonly theme: GlobalThemeType;
    readonly isSmallLayout: boolean;
}

const BoxWrap = styled.div`
    position: relative;
    padding-bottom: ${(props: BoxWrapProps) => {
        if (props.isSmallLayout) {
            return 0;
        }
        return props.theme.spacing(15);
    }}px;
`;

const AuditSavedResultPage: React.FC<SavedResultProps> = (props) => {
    const { match } = props;

    const { t } = useTranslation();
    const theme = useTheme<GlobalThemeType>();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const setUrlConfirmed = useSetUrlConfirmedAction();
    const setLoadingAuditStarted = useSetLoadingAuditStartedAction();
    const errorLoadingSavedAudit = useErrorLoadingSavedAudit();
    const savedAuditRequestResult = useSavedAuditRequestResult();

    const onEnteredUrl = useCallback(
        async (url: string) => {
            await setUrlConfirmed(url, UrlSource.headerSavedAudit);
        },
        [setUrlConfirmed],
    );

    const auditId = useMemo<UUID>(() => {
        const optionAuditUuidString = match.params.uuid;
        const optionAuditUuid = UUID.decode(optionAuditUuidString);
        if (isLeft(optionAuditUuid)) {
            throw new Error('Unexpected exception');
        }
        return optionAuditUuid.right;
    }, [match.params.uuid]);

    useEffect(() => {
        setLoadingAuditStarted(auditId);
    }, [auditId, setLoadingAuditStarted]);

    return (
        <BoxWrap theme={theme} isSmallLayout={isSmallLayout}>
            <Header defaultUrl={undefined} onEnteredUrl={onEnteredUrl} />
            {savedAuditRequestResult !== undefined && (
                <AuditResult finishedAt={savedAuditRequestResult.finishedAt} url={savedAuditRequestResult.url} report={savedAuditRequestResult.report} auditUuid={auditId} />
            )}
            {savedAuditRequestResult === undefined && errorLoadingSavedAudit === null && <CircularProgress />}
            {errorLoadingSavedAudit !== null && (
                <Container maxWidth={'md'}>
                    <Box display="flex" justifyContent="center">
                        <Box mt={6} mb={4} width={500}>
                            <Typography variant={'h3'} align="left">
                                Error
                            </Typography>
                            <Box mt={4}>
                                <Typography align="left">{t('pages.result.error.loadingAuditFailed')}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            )}
            <FooterDefault />
        </BoxWrap>
    );
};

export default AuditSavedResultPage;
