import * as z from 'zod';
import { ApiErrors, generalErrors } from './api';
import { AuditErrorType } from '../store/audit/types/auditErrorType';
import { AuditSource } from '../component/page/audit/categories/category/header/AuditSource';
import { AuditStatus } from '../model/audit/auditStatus';
import { getApiPrefix } from './prefix';
import { getEnumValues } from '../utils/enum/enumUtils';
import { UUID } from 'io-ts-types/UUID';
import { ZonedDateTime } from '@js-joda/core';
import i18next, { TFunction } from 'i18next';

export const endpointAuditGet = (uuid: UUID) => {
    const idRawString: string = uuid.toString();
    const url = 'v1/audit/' + idRawString;
    return getApiPrefix(url);
};

export const auditGetErrorCodes: ApiErrors = {
    CONNECTION_FAILED: {
        type: AuditErrorType.connectionFailed,
        translation: (t: TFunction) => t('pages.result.error.connectionFailed'),
    },
    NOT_HTML: {
        type: AuditErrorType.notHtml,
        translation: (t: TFunction) => t('pages.result.error.notHtml'),
    },
    SIZE_LIMIT_EXCEEDED: {
        type: AuditErrorType.sizeLimitExceeded,
        translation: (t: TFunction) => t('pages.result.error.sizeLimitExceeded'),
    },
    UNPROCESSABLE: generalErrors.failed,
};

const auditDetailOpportunitySchema = z.object({
    type: z.literal('opportunity'),
    headings: z.array(
        z.object({
            key: z.string().nullable(),
            granularity: z.number().optional(),
            label: z.any().optional(),
            valueType: z.any().optional(),
            subItemsHeading: z.any().optional(),
        }),
    ),
    items: z.array(z.any()),
    overallSavingsMs: z.number().optional(),
    overallSavingsBytes: z.number().optional(),
});

export type AuditDetailOpportunity = z.infer<typeof auditDetailOpportunitySchema>;

const auditDetailTableSchema = z.object({
    type: z.literal('table'),
    headings: z.array(
        z.object({
            key: z.string(),
            valueType: z.string().optional(),
            itemType: z.string().optional(),
            label: z.string().optional(),
            text: z.string().optional(),
            displayUnit: z.string().optional(),
            granularity: z.number().optional(),
            subItemsHeading: z.any().optional(),
        }),
    ),
    items: z.array(z.any()),
    summary: z.any().optional(),
    debugData: z.any().optional(),
});

export type AuditDetailTable = z.infer<typeof auditDetailTableSchema>;

const auditDetailSchema = z.union([auditDetailOpportunitySchema, auditDetailTableSchema]);
export type AuditDetail = z.infer<typeof auditDetailSchema>;

const auditSchema = z.object({
    title: z.string(),
    description: z.string(),
    detail: auditDetailSchema.optional(),
    displayValue: z.string().optional(),
});
export type ReportAudit = z.infer<typeof auditSchema>;

const auditListSchema = z.array(auditSchema);
export type ReportAuditList = z.infer<typeof auditListSchema>;

const auditsSchema = z.object({
    failed: auditListSchema,
    opportunities: auditListSchema,
    passed: auditListSchema,
});
export type ReportAudits = z.infer<typeof auditsSchema>;

const categorySchema = z.object({
    id: z.string(),
    name: z.string(),
    score: z.number(),
    source: z.nativeEnum(AuditSource),
    failed: z.boolean().optional(),
    audits: auditsSchema,
});
export type ReportCategory = z.infer<typeof categorySchema>;

const categoriesSchema = z.array(categorySchema);
export type ReportCategories = z.infer<typeof categoriesSchema>;

export enum DeviceCategory {
    mobile = 'mobile',
    desktop = 'desktop',
    common = 'common',
}

export const translateDeviceCategory = (deviceCategory: DeviceCategory): string => {
    switch (deviceCategory) {
        case DeviceCategory.mobile:
            return i18next.t('deviceCategory.mobile');
        case DeviceCategory.desktop:
            return i18next.t('deviceCategory.desktop');
        case DeviceCategory.common:
            return i18next.t('deviceCategory.common');
        default:
            throw new Error('unexpected DeviceCategory in translateDeviceCategory');
    }
};

const deviceCategoryEnumValues = getEnumValues<string>(DeviceCategory) as [string];

const deviceCategorySchema = z.object({
    id: z.enum(deviceCategoryEnumValues),
    name: z.string(),
    categories: categoriesSchema,
});
export type ReportDeviceCategory = z.infer<typeof deviceCategorySchema>;

const deviceCategoriesSchema = z.array(deviceCategorySchema);
export type ReportDeviceCategories = z.infer<typeof deviceCategoriesSchema>;

const reportSchema = z.object({
    score: z.number(),
    results: z.object({
        failed: z.number(),
        opportunities: z.number(),
        passed: z.number(),
    }),
    desktopScreenshot: z.string(),
    mobileScreenshot: z.string(),
    deviceCategories: deviceCategoriesSchema,
});
export type Report = z.infer<typeof reportSchema>;

export const auditGetResultSchema = z.object({
    uuid: z.string().uuid(),
    status: z.nativeEnum(AuditStatus),
    url: z.string().url().optional(),
    finishedAt: z
        .string()
        .refine(
            (value) => {
                try {
                    ZonedDateTime.parse(value);
                    return true;
                } catch (error) {
                    return false;
                }
            },
            {
                message: 'Invalid datetime',
            },
        )
        .optional(),
    isReachable: z.boolean().optional(),
    report: reportSchema.optional(),
});

export const ApiResponseErrorSchema = z.object({
    success: z.literal(false),
    errors: z.array(
        z.object({
            errorCode: z.string(),
            path: z.union([z.string().nullable(), z.array(z.string())]),
            hints: z.array(z.string()).optional(),
        }),
    ),
});
