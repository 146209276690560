import { Box, Grid, makeStyles, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CircleChartSize } from '../../../chart/circle/CircleChartSize';
import { convert } from '@js-joda/core';
import { flc } from '../../../../utils/strings/firstLetterCapitalize';
import { GlobalThemeType } from '../../../../theme/globalTheme';
import { OverviewProps } from './overviewProps';
import { useTranslation } from 'react-i18next';
import CircleChart from '../../../chart/circle';
import ColorInfo from './colorInfo';
import EvaluationList from './evaluationList';
import React from 'react';
import ShareResult from '../shareResult';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'left',
        flexGrow: 1,
    },
    thumbnail: {
        display: 'block',
    },
}));

interface TestedOnBoxProps {
    readonly theme: GlobalThemeType;
}

const TestedOnBox = styled.div`
    margin-top: ${(props: TestedOnBoxProps) => props.theme.spacing(1)}px;
`;

interface ScreenshotsBoxProps {
    readonly isSmallLayout: boolean;
    readonly theme: GlobalThemeType;
}

const ScreenshotsBox = styled.div`
    display: flex;
    margin-top: ${(props: ScreenshotsBoxProps) => {
        if (props.isSmallLayout) {
            return props.theme.spacing(6);
        }
        return 0;
    }}px;
`;

const BoxWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

interface ShareResultWrapProps {
    readonly theme: GlobalThemeType;
}

const ShareResultWrap = styled.div`
    display: flex;
    margin-top: ${(props: ShareResultWrapProps) => props.theme.spacing(6)}px;
`;

const Overview: React.FC<OverviewProps> = (props) => {
    const { score, errors, warnings, passed, desktopScreenshot, mobileScreenshot, url, finishedAt, auditUuid } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme<GlobalThemeType>();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <BoxWrap>
            <Grid container justify="space-between" className={classes.root}>
                <Grid item>
                    <Typography
                        style={{
                            fontSize: 46,
                        }}
                        variant={'h3'}
                    >
                        {url}
                    </Typography>
                    <TestedOnBox theme={theme}>
                        <Typography component={'span'} style={{}} color={'primary'}>
                            {flc(t('pages.result.overview.testedOn'))}:{' '}
                        </Typography>
                        <Typography component={'span'} style={{}}>
                            {convert(finishedAt).toDate().toLocaleString()}
                        </Typography>
                    </TestedOnBox>

                    <Box mt={4}>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item>
                                <Tooltip title="Total score is calculated as average from all executed audits.">
                                    <CircleChart percent={score} size={CircleChartSize.medium} />
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <ColorInfo />
                            </Grid>
                        </Grid>
                        <Box mt={4}>
                            <EvaluationList passed={passed} warning={warnings} error={errors} />
                        </Box>
                    </Box>
                </Grid>
                <ScreenshotsBox theme={theme} isSmallLayout={isSmallLayout}>
                    <Box position="relative" width={265}>
                        <Box borderRadius={5} boxShadow={3} overflow="hidden">
                            <img className={classes.thumbnail} width={265} src={desktopScreenshot} alt={t('pages.result.overview.desktopScreenshot')} />
                        </Box>
                        <Box borderRadius={5} boxShadow={3} overflow="hidden" position="absolute" top="25%" left="50%">
                            <img className={classes.thumbnail} width={100} src={mobileScreenshot} alt={t('pages.result.overview.mobileScreenshot')} />
                        </Box>
                    </Box>
                </ScreenshotsBox>
            </Grid>
            <ShareResultWrap theme={theme}>
                <ShareResult auditUuid={auditUuid} />
            </ShareResultWrap>
        </BoxWrap>
    );
};

export default Overview;
