import { makeStyles } from '@material-ui/core';
import { TableDetailThumbnailProps } from './tableDetailThumbnailProps';
import React from 'react';

const useStyles = makeStyles(() => ({
    root: ({ url }: TableDetailThumbnailProps) => ({
        width: 40,
        height: 40,
        backgroundImage: `url(${url})`,
        backgroundSize: 50,
        backgroundColor: 'darkgray',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }),
}));

const TableDetailThumbnail: React.FC<TableDetailThumbnailProps> = (props) => {
    const classes = useStyles(props);

    return <div className={classes.root} />;
};

export default TableDetailThumbnail;
