import { createStyles, LinearProgress, Typography, useTheme, withStyles } from '@material-ui/core';
import { getResultTypeByPercent, ResultType } from '../../pictogram/result/ResultType';
import { GlobalThemeType } from '../../../theme/globalTheme';
import { LinearChartProps } from './linearChartProps';
import { LinearProgressProps } from '@material-ui/core/LinearProgress/LinearProgress';
import { RoundType } from './roundType';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const BoxWrap = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;

interface LinearProgressWrapProps {
    readonly theme: GlobalThemeType;
}

const LinearProgressWrap = styled.div`
    height: ${(props: LinearProgressWrapProps) => props.theme.typography.fontSize};
    width: calc(100% - 80px);
`;

export interface MyLinearProgressProps extends LinearProgressProps {
    readonly type: ResultType;
}

const MyLinearProgress = withStyles((theme: GlobalThemeType) =>
    createStyles({
        root: {
            height: theme.typography.fontSize,
            borderRadius: theme.typography.fontSize / 4,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: ({ type, value }: MyLinearProgressProps) => {
            const colors = {
                passed: {
                    primary: theme.palette.success.dark,
                    secondary: theme.palette.success.dark,
                },
                warning: {
                    primary: theme.palette.warning.dark,
                    secondary: theme.palette.warning.dark,
                },
                error: {
                    primary: theme.palette.error.dark,
                    secondary: theme.palette.error.dark,
                },
            };

            const percent = value === undefined ? 0 : value;

            return {
                borderRadius: theme.typography.fontSize / 4,
                background: `linear-gradient(90deg, ${colors[type].primary} ${100 - percent}%, ${colors[type].secondary} 100%)`,
            };
        },
    }),
)(LinearProgress);

const LinearChart: React.FC<LinearChartProps> = (props) => {
    const { percent, type: typeFromProps, roundType } = props;

    const theme = useTheme<GlobalThemeType>();

    const type = typeFromProps === undefined ? getResultTypeByPercent(percent) : typeFromProps;

    const percentString = useMemo<string>(() => {
        switch (roundType) {
            case RoundType.integer:
                return percent.toFixed(0);
            case RoundType.oneDecimalPlace:
                return percent.toFixed(1);
            default:
                return percent.toFixed(1);
        }
    }, [percent, roundType]);

    return (
        <BoxWrap>
            <LinearProgressWrap theme={theme}>
                <MyLinearProgress variant="determinate" value={percent} type={type} />
            </LinearProgressWrap>
            <Typography
                align={'right'}
                style={{
                    fontSize: 20,
                    width: 80,
                    fontWeight: 500,
                }}
            >
                {percentString}%
            </Typography>
        </BoxWrap>
    );
};

export default LinearChart;
