import { isNone } from 'fp-ts/Option';
import { NonNegativeInteger, prismNonNegativeInteger } from 'newtype-ts/lib/NonNegativeInteger';

export const numberToNonNegativeInteger = (number: number): NonNegativeInteger => {
    const option = prismNonNegativeInteger.getOption(number);
    if (isNone(option)) {
        throw new Error('unexpected error');
    }
    return option.value;
};
