import { ReportAuditsProps } from './reportAuditsProps';
import { ResultType } from '../../../../../pictogram/result/ResultType';
import AuditSection from './auditSection';
import React from 'react';
import styled from 'styled-components';

const BoxWrap = styled.div``;

const ReportAudits: React.FC<ReportAuditsProps> = (props) => {
    const { audits } = props;

    return (
        <BoxWrap>
            <AuditSection auditList={audits.failed} resultType={ResultType.error} />
            <AuditSection auditList={audits.opportunities} resultType={ResultType.warning} />
            <AuditSection auditList={audits.passed} resultType={ResultType.passed} />
        </BoxWrap>
    );
};

export default ReportAudits;
