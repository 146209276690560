import { ColorInfoItemProps } from './colorInfoItemProps';
import { GlobalThemeType } from '../../../../../../theme/globalTheme';
import { ResultType } from '../../../../../pictogram/result/ResultType';
import { Typography, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const BoxWrap = styled.div`
    display: flex;
    align-items: center;
`;

interface DotProps {
    readonly resultType: ResultType;
    readonly theme: GlobalThemeType;
}

const Dot = styled.div`
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: ${(props: DotProps) => {
        switch (props.resultType) {
            case ResultType.error:
                return props.theme.palette.error.main;
            case ResultType.warning:
                return props.theme.palette.warning.main;
            case ResultType.passed:
                return props.theme.palette.success.main;
            default:
                throw new Error('unexpected ResultType in ColorInfoItem dotbackground-color');
        }
    }};
    margin-right: ${(props: DotProps) => props.theme.spacing(1)}px;
`;

const ColorInfoItem: React.FC<ColorInfoItemProps> = (props) => {
    const { resultType } = props;

    const theme = useTheme<GlobalThemeType>();

    const text = useMemo(() => {
        switch (resultType) {
            case ResultType.passed:
                return '90 – 100';
            case ResultType.warning:
                return '50 – 89';
            case ResultType.error:
                return '0 – 49';
            default:
                throw new Error('unexpected ResultType in ColorInfoItem text');
        }
    }, [resultType]);

    return (
        <BoxWrap>
            <Dot resultType={resultType} theme={theme} />
            <Typography
                style={{
                    fontSize: 18,
                }}
            >
                {text}
            </Typography>
        </BoxWrap>
    );
};

export default ColorInfoItem;
