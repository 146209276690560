import { CircleChartProps } from './circleChartProps';
import { getBackgroundColorByResultType } from '../getBackgroundColorByResultType';
import { getBoxSizeByCircleChartSize, getFontSizeByCircleChartSize, getLineWidthByCircleChartSize } from './config';
import { getColorByResultType, getResultTypeByPercent, ResultType } from '../../pictogram/result/ResultType';
import { GlobalThemeType } from '../../../theme/globalTheme';
import { PieChart } from 'react-minimal-pie-chart';
import { Typography, useTheme } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface BoxWrapProps {
    readonly dimension: number;
    readonly theme: GlobalThemeType;
}

const BoxWrap = styled.div`
    height: ${(props: BoxWrapProps) => {
        return props.theme.spacing(props.dimension);
    }}px;
    width: ${(props: BoxWrapProps) => {
        return props.theme.spacing(props.dimension);
    }}px;
    position: relative;
    display: flex;
`;

interface BackgroundCircleWrapProps {
    readonly dimension: number;
    readonly lineWidth: number;
    readonly resultType: ResultType;
    readonly theme: GlobalThemeType;
}

const BackgroundCircleWrap = styled.div`
    position: absolute;
    border-style: solid;
    border-radius: 50%;
    border-color: ${(props: BackgroundCircleWrapProps) => getBackgroundColorByResultType(props.resultType, props.theme)};
    border-width: ${(props: BackgroundCircleWrapProps) => props.lineWidth}px;
    height: ${(props: BackgroundCircleWrapProps) => {
        return props.theme.spacing(props.dimension) - props.lineWidth * 2;
    }}px;
    width: ${(props: BackgroundCircleWrapProps) => {
        return props.theme.spacing(props.dimension) - props.lineWidth * 2;
    }}px;
    z-index: 1;
    background-color: ${(props: BackgroundCircleWrapProps) => getBackgroundColorByResultType(props.resultType, props.theme)};
`;

const PieChartWrap = styled.div`
    position: absolute;
    z-index: 2;
`;

interface ScoreWrapProps {
    readonly dimension: number;
    readonly theme: GlobalThemeType;
}

const ScoreWrap = styled.div`
    display: flex;
    position: absolute;
    z-index: 3;
    align-items: center;
    justify-content: center;
    height: ${(props: ScoreWrapProps) => {
        return props.theme.spacing(props.dimension);
    }}px;
    width: ${(props: ScoreWrapProps) => {
        return props.theme.spacing(props.dimension);
    }}px;
`;

const CircleChart: React.FC<CircleChartProps> = React.forwardRef<HTMLDivElement, CircleChartProps>((props, ref) => {
    const { percent, size } = props;

    const theme = useTheme<GlobalThemeType>();

    const lineWidthInPx = (theme.spacing(getBoxSizeByCircleChartSize(size)) / 100 / 2) * getLineWidthByCircleChartSize(size);
    const resultType = getResultTypeByPercent(percent);

    return (
        <BoxWrap {...props} theme={theme} dimension={getBoxSizeByCircleChartSize(size)} ref={ref}>
            <BackgroundCircleWrap theme={theme} dimension={getBoxSizeByCircleChartSize(size)} lineWidth={lineWidthInPx} resultType={resultType} />
            <PieChartWrap>
                <PieChart
                    data={[
                        {
                            value: percent,
                            color: getColorByResultType(resultType, theme),
                        },
                    ]}
                    rounded={false}
                    totalValue={100}
                    animate={true}
                    lineWidth={getLineWidthByCircleChartSize(size)}
                    startAngle={270}
                />
            </PieChartWrap>
            <ScoreWrap dimension={getBoxSizeByCircleChartSize(size)} theme={theme}>
                <Typography
                    style={{
                        color: getColorByResultType(resultType, theme),
                        fontSize: getFontSizeByCircleChartSize(size),
                        fontWeight: 'bold',
                    }}
                >
                    {percent.toFixed(1)}
                </Typography>
            </ScoreWrap>
        </BoxWrap>
    );
});

export default CircleChart;
