import { GlobalThemeType } from '../../../../../theme/globalTheme';
import { ResultType } from '../../../../pictogram/result/ResultType';
import { useTheme } from '@material-ui/core';
import ColorInfoItem from './item';
import React from 'react';
import styled from 'styled-components';

interface BoxWrapProps {
    readonly theme: GlobalThemeType;
}

const BoxWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: ${(props: BoxWrapProps) => props.theme.spacing(12)}px;
    justify-content: space-between;
`;

const ColorInfo: React.FC<{}> = () => {
    const theme = useTheme<GlobalThemeType>();

    return (
        <BoxWrap theme={theme}>
            <ColorInfoItem resultType={ResultType.passed} />
            <ColorInfoItem resultType={ResultType.warning} />
            <ColorInfoItem resultType={ResultType.error} />
        </BoxWrap>
    );
};

export default ColorInfo;
