import { Link } from 'react-scroll/modules';
import { MenuItem } from '@material-ui/core';
import { ScrollMenuItemProps } from './ScrollMenuItemProps';
import React from 'react';

const ScrollMenuItem: React.FC<ScrollMenuItemProps> = (props) => {
    const { active, to, onSetActive, onSetInactive } = props;

    return (
        <Link to={to} onSetActive={onSetActive} onSetInactive={onSetInactive} spy={true}>
            <MenuItem selected={active}>{props.children}</MenuItem>
        </Link>
    );
};

export default ScrollMenuItem;
