import { AppState } from '../../../../appState';
import { getAuditId } from './getAuditId';
import { useSelector } from 'react-redux';
import { UUID } from 'io-ts-types/UUID';

const useAuditId = (): UUID | undefined => {
    const auditId = useSelector<AppState, UUID | undefined>((state) => {
        return getAuditId(state);
    });

    return auditId;
};

export default useAuditId;
