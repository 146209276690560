import { Box } from '@material-ui/core';
import { EvaluationRowProps } from './evaluationRowProps';
import { flc } from '../../../../../utils/strings/firstLetterCapitalize';
import { ResultPictogramTextType } from '../../../../pictogram/resultWithText/ResultPictogramTextType';
import { useTranslation } from 'react-i18next';
import LinearChart from '../../../../chart/linear';
import React from 'react';
import ResultPictogramWithText from '../../../../pictogram/resultWithText';

const EvaluationRow: React.FC<EvaluationRowProps> = (props) => {
    const { type, percent, roundType } = props;

    const { t } = useTranslation();

    const texts = {
        passed: flc(t('pages.result.overview.evaluation.passed')),
        warning: flc(t('pages.result.overview.evaluation.warning')),
        error: flc(t('pages.result.overview.evaluation.errors')),
    };

    return (
        <Box display="flex" alignItems="center">
            <Box width={166}>
                <ResultPictogramWithText type={type} textType={ResultPictogramTextType.normal} text={texts[type]} />
            </Box>
            <Box flex={1} minWidth={120}>
                <LinearChart percent={percent} type={type} roundType={roundType} />
            </Box>
        </Box>
    );
};

export default EvaluationRow;
