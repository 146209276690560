import { AppState } from '../../../../appState';
import { getEnteredUrl } from './getEnteredUrl';
import { useSelector } from 'react-redux';

const useEnteredUrl = (): string | undefined => {
    const enteredUrl = useSelector<AppState, string | undefined>((state) => {
        return getEnteredUrl(state);
    });

    return enteredUrl;
};

export default useEnteredUrl;
