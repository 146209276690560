import { AppState } from '../../../../appState';
import { getLoadingAuditResult } from './getLoadingAuditResult';
import { useSelector } from 'react-redux';

const useLoadingAuditResult = (): boolean => {
    const result = useSelector<AppState, boolean>((state) => {
        return getLoadingAuditResult(state);
    });

    return result;
};

export default useLoadingAuditResult;
