import { Box } from '@material-ui/core';
import { EvaluationListProps } from './evaluationListProps';
import { ResultType } from '../../../../pictogram/result/ResultType';
import { RoundType } from '../../../../chart/linear/roundType';
import EvaluationRow from '../evaluationRow';
import React from 'react';

const EvaluationList: React.FC<EvaluationListProps> = (props) => {
    const { passed, warning, error } = props;

    return (
        <Box>
            <EvaluationRow type={ResultType.passed} percent={passed} roundType={RoundType.integer} />
            <Box mt={0.5}>
                <EvaluationRow type={ResultType.warning} percent={warning} roundType={RoundType.integer} />
            </Box>
            <Box mt={0.5}>
                <EvaluationRow type={ResultType.error} percent={error} roundType={RoundType.integer} />
            </Box>
        </Box>
    );
};

export default EvaluationList;
