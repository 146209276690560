import { Box, Typography, useTheme } from '@material-ui/core';
import { CategoryHeaderTitleProps } from './categoryHeaderTitleProps';
import { flc } from '../../../../../../../utils/strings/firstLetterCapitalize';
import { GlobalThemeType } from '../../../../../../../theme/globalTheme';
import { translateDeviceCategory } from '../../../../../../../api/endpointAuditGet';
import React from 'react';

const CategoryHeaderTitle: React.FC<CategoryHeaderTitleProps> = (props) => {
    const { text, deviceCategory, failed } = props;

    const theme = useTheme<GlobalThemeType>();

    return (
        <Box display={'flex'} alignItems={'center'}>
            <Typography
                variant={'h3'}
                style={
                    failed === true
                        ? {
                              color: theme.palette.text.primary,
                          }
                        : undefined
                }
            >
                {flc(translateDeviceCategory(deviceCategory))} / {text}
            </Typography>
        </Box>
    );
};

export default CategoryHeaderTitle;
