import { AppAction } from '../appAction';
import { DefaultPageReducerState } from './state';
import { Reducer } from 'redux';

const getInitialState = (): DefaultPageReducerState => {
    return {};
};

const defaultPageReducer: Reducer<DefaultPageReducerState, AppAction> = (state = getInitialState(), action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default defaultPageReducer;
