import { GlobalThemeType } from '../../theme/globalTheme';
import { ResultType } from '../pictogram/result/ResultType';

export const getBackgroundColorByResultType = (resultType: ResultType, theme: GlobalThemeType): string => {
    switch (resultType) {
        case ResultType.error:
            return theme.palette.error.light;
        case ResultType.warning:
            return theme.palette.warning.light;
        case ResultType.passed:
            return theme.palette.success.light;
        default:
            throw new Error('Unexpected ResultType in getColorByResultType');
    }
};
