import { auditRoute, auditSavedResultRoute, defaultRoute } from './route/route';
import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuditPage from './component/page/audit';
import AuditSavedResultPage from './component/page/audit/savedResult';
import CookieConsent from './component/cookieConsent';
import DefaultPage from './component/page/default';
import React from 'react';

const App: React.FC<{}> = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('appName')}</title>
            </Helmet>
            <Box textAlign="center">
                <Switch>
                    <Route path={defaultRoute.pattern()} exact component={DefaultPage} />
                    <Route path={auditRoute.pattern()} exact component={AuditPage} />
                    <Route path={auditSavedResultRoute.pattern()} exact component={AuditSavedResultPage} />
                </Switch>
            </Box>
            <CookieConsent />
        </>
    );
};

export default App;
