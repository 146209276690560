import * as z from 'zod';
import { Button, FormControl, FormHelperText, Hidden, OutlinedInput, useMediaQuery, useTheme } from '@material-ui/core';
import { DefaultFormProps } from './defaultFormProps';
import { getUrlRegExp } from '../../../../utils/strings/urlRegExp';
import { GlobalThemeType } from '../../../../theme/globalTheme';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';

const ContentWrap = styled.div`
    display: flex;
    vertical-align: center;
`;

interface TextFieldWrapProps {
    readonly isSmallLayout: boolean;
}

const TextFieldWrap = styled.div`
    width: ${(props: TextFieldWrapProps) => {
        return props.isSmallLayout ? '100%' : '448px';
    }};
`;

interface ButtonWrapProps {
    readonly isSmallLayout: boolean;
    readonly theme: GlobalThemeType;
}

const ButtonWrap = styled.div`
    margin-left: ${(props: ButtonWrapProps) => {
        return props.isSmallLayout ? 0 : props.theme.spacing(2);
    }}px;
`;

const formInputsSchema = z.object({
    url: z.string().regex(getUrlRegExp()),
});

type FormInputs = z.infer<typeof formInputsSchema>;

const DefaultForm: React.FC<DefaultFormProps> = (props) => {
    const { onEnteredUrl } = props;

    const { t } = useTranslation();
    const theme = useTheme<GlobalThemeType>();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        handleSubmit,
        formState: { errors: formErrors },
        register,
    } = useForm<FormInputs>({
        resolver: zodResolver(formInputsSchema),
    });
    const { ref: urlFormRef, ...urlFormProps } = register('url');

    const onSubmit = useCallback(
        (data: FormInputs) => {
            onEnteredUrl(data.url);
        },
        [onEnteredUrl],
    );

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
                flex: 1,
                width: '100%',
            }}
        >
            <ContentWrap>
                <TextFieldWrap isSmallLayout={isSmallLayout}>
                    <FormControl size={isSmallLayout ? 'medium' : 'small'} fullWidth={true}>
                        <OutlinedInput
                            {...urlFormProps}
                            inputRef={urlFormRef}
                            placeholder={t('pages.default.form.url.placeholder')}
                            style={{
                                backgroundColor: '#FFFFFF',
                            }}
                            endAdornment={isSmallLayout ? <SearchIcon color={'primary'} onClick={handleSubmit(onSubmit)} /> : undefined}
                            fullWidth={true}
                        />
                        {formErrors.url !== undefined && (
                            <FormHelperText
                                style={{
                                    marginLeft: theme.spacing(2),
                                    color: theme.palette.error.main,
                                }}
                            >
                                {t('pages.default.form.url.error.invalid')}
                            </FormHelperText>
                        )}
                    </FormControl>
                </TextFieldWrap>
                <Hidden smDown={true}>
                    <ButtonWrap isSmallLayout={isSmallLayout} theme={theme}>
                        <Button
                            color={'primary'}
                            onClick={handleSubmit(onSubmit)}
                            variant={'contained'}
                            style={{
                                height: 40,
                            }}
                        >
                            {t('pages.default.form.submit.name')}
                        </Button>
                    </ButtonWrap>
                </Hidden>
            </ContentWrap>
        </form>
    );
};

export default DefaultForm;
