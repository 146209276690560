import { AppLanguageEnum } from './appLanguage';
import { initReactI18next } from 'react-i18next';
import { TranslationNamespaceEnum } from './translationNamespace';
import i18next, { i18n } from 'i18next';
import localeEn from './locale/en/app.json';

const FALLBACK_TO_EN_KEY = 'fallbackToEn';

i18next
    .use(initReactI18next)
    .use({
        type: 'postProcessor',
        name: FALLBACK_TO_EN_KEY,
        process: (value: string, key: string, options: object, translator: i18n) => {
            if (value === key && translator.language !== AppLanguageEnum.english) {
                return translator.t(key, { lng: AppLanguageEnum.english });
            }

            return value;
        },
    })
    .init({
        fallbackLng: AppLanguageEnum.english,

        ns: [TranslationNamespaceEnum.app],
        defaultNS: TranslationNamespaceEnum.app,
        fallbackNS: TranslationNamespaceEnum.app,

        interpolation: {
            escapeValue: false, // React already ensure XSS protection
        },
        react: {
            useSuspense: false,
        },
        postProcess: [FALLBACK_TO_EN_KEY],
    });

i18next.addResourceBundle(AppLanguageEnum.english, TranslationNamespaceEnum.app, localeEn);

export default i18next;
