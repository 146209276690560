import { AuditSectionProps } from './auditSectionProps';
import { createStyles, Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Typography, useTheme, withStyles } from '@material-ui/core';
import { flc } from '../../../../../../../utils/strings/firstLetterCapitalize';
import { getColorByResultType, ResultType, translateResultType } from '../../../../../../pictogram/result/ResultType';
import { GlobalThemeType } from '../../../../../../../theme/globalTheme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import ReportAuditList from './auditList';
import styled from 'styled-components';

const Accordion = withStyles({
    root: {
        border: 'none',
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        borderBottom: 0,
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() =>
    createStyles({
        root: {
            display: 'block',
            padding: 0,
        },
    }),
)(MuiAccordionDetails);

const HeaderWrap = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const getDefaultExpandedByResultType = (resultType: ResultType): boolean => {
    switch (resultType) {
        case ResultType.error:
            return true;
        case ResultType.warning:
            return true;
        case ResultType.passed:
            return false;
        default:
            throw new Error('unexpected ResultType in getDefaultExpandedByResultType');
    }
};

const AuditSection: React.FC<AuditSectionProps> = (props) => {
    const { auditList, resultType } = props;

    const theme = useTheme<GlobalThemeType>();

    const [expanded, setExpanded] = React.useState<boolean>(getDefaultExpandedByResultType(resultType));

    const handleChange = () => {
        setExpanded(!expanded);
    };

    if (auditList.length === 0) {
        return null;
    }

    return (
        <Accordion expanded={expanded} onChange={handleChange} variant={'outlined'}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <HeaderWrap>
                    <Typography
                        variant="h4"
                        style={{
                            fontSize: 26,
                            color: getColorByResultType(resultType, theme),
                            fontWeight: 500,
                        }}
                    >
                        {flc(translateResultType(resultType))}
                    </Typography>
                    <Typography
                        style={{
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    >
                        {auditList.length} {flc(translateResultType(resultType))}
                    </Typography>
                </HeaderWrap>
            </AccordionSummary>
            <AccordionDetails>
                <ReportAuditList auditList={auditList} type={resultType} />
            </AccordionDetails>
        </Accordion>
    );
};

export default AuditSection;
