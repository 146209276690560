import * as z from 'zod';
import { Button, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { getUrlRegExp } from '../../../utils/strings/urlRegExp';
import { GlobalThemeType } from '../../../theme/globalTheme';
import { HeaderFormProps } from './headerFormProps';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import styled from 'styled-components';

interface BoxWrapProps {
    readonly isSmallLayout: boolean;
}

const BoxWrap = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: ${(props: BoxWrapProps) => {
        return props.isSmallLayout ? 'column' : 'row';
    }};
`;

interface ButtonWrapProps {
    readonly isSmallLayout: boolean;
    readonly theme: GlobalThemeType;
}

const ButtonWrap = styled.div`
    margin-left: ${(props: ButtonWrapProps) => (props.isSmallLayout ? 0 : props.theme.spacing(2))}px;
    margin-top: ${(props: ButtonWrapProps) => (props.isSmallLayout ? props.theme.spacing(1) : 0)}px;
`;

const formInputsSchema = z.object({
    url: z.string().regex(getUrlRegExp()),
});

type FormInputs = z.infer<typeof formInputsSchema>;

const HeaderForm: React.FC<HeaderFormProps> = (props) => {
    const { defaultUrl, onEnteredUrl } = props;

    const { t } = useTranslation();
    const theme = useTheme<GlobalThemeType>();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        handleSubmit,
        register,
        formState: { errors: formErrors },
    } = useForm<FormInputs>({
        resolver: zodResolver(formInputsSchema),
        defaultValues: {
            url: defaultUrl,
        },
    });
    const { ref: urlFormRef, ...urlFormProps } = register('url');

    const onSubmit = (data: FormInputs) => {
        onEnteredUrl(data.url);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <BoxWrap isSmallLayout={isSmallLayout}>
                <TextField
                    {...urlFormProps}
                    inputRef={urlFormRef}
                    variant={'outlined'}
                    placeholder={t('header.form.url.placeholder')}
                    size={'small'}
                    helperText={formErrors.url !== undefined && t('pages.default.form.url.error.invalid')}
                    style={{
                        ...(isSmallLayout ? {} : { width: 400 }),
                    }}
                    fullWidth={isSmallLayout}
                />
                <ButtonWrap theme={theme} isSmallLayout={isSmallLayout}>
                    <Button
                        color={'primary'}
                        variant={'outlined'}
                        style={{
                            height: '40px',
                        }}
                        onClick={handleSubmit(onSubmit)}
                        fullWidth={isSmallLayout}
                    >
                        {t('header.form.submit.name')}
                    </Button>
                </ButtonWrap>
            </BoxWrap>
        </form>
    );
};

export default HeaderForm;
