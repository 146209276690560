import { AppBar, Container, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import { GlobalThemeType } from '../../theme/globalTheme';
import { HeaderProps } from './headerProps';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderForm from './form';
import LogoHover from '../../images/logo-bwt-hover.svg';
import LogoPassive from '../../images/logo-bwt-passive.svg';
import React from 'react';
import styled from 'styled-components';

interface ContentWrapProps {
    readonly isForm: boolean;
    readonly isSmallLayout: boolean;
    readonly theme: GlobalThemeType;
}

const ContentWrap = styled.div`
    display: flex;
    justify-content: ${(props: ContentWrapProps) => {
        return props.isSmallLayout || props.isForm ? 'center' : 'left';
    }};
    flex-direction: ${(props: ContentWrapProps) => {
        return props.isSmallLayout ? 'column' : 'row';
    }};
    width: 100%;
    margin-bottom: ${(props: ContentWrapProps) => {
        return props.isSmallLayout ? props.theme.spacing(2) : 0;
    }}px;
    margin-top: ${(props: ContentWrapProps) => {
        return props.isSmallLayout ? props.theme.spacing(2) : 0;
    }}px;
`;

const Header: React.FC<HeaderProps> = (props) => {
    const { defaultUrl, onEnteredUrl } = props;

    const { t } = useTranslation();
    const theme = useTheme<GlobalThemeType>();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <AppBar position="relative" color="transparent">
            <Container maxWidth={isSmallLayout ? undefined : 'xl'}>
                <Toolbar>
                    <ContentWrap isSmallLayout={isSmallLayout} theme={theme} isForm={onEnteredUrl !== null}>
                        <Link to="/">
                            <img
                                src={LogoPassive}
                                title={t('appName')}
                                alt={t('appName')}
                                style={{
                                    width: theme.spacing(12),
                                    marginRight: isSmallLayout ? 0 : theme.spacing(4),
                                    marginBottom: isSmallLayout && onEnteredUrl !== null ? theme.spacing(1) : 0,
                                }}
                                onMouseOver={(e) => (e.currentTarget.src = LogoHover)}
                                onMouseOut={(e) => (e.currentTarget.src = LogoPassive)}
                            />
                        </Link>

                        {onEnteredUrl !== null && <HeaderForm defaultUrl={defaultUrl} onEnteredUrl={onEnteredUrl} />}
                    </ContentWrap>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
