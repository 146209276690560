import { getShortUrlPath } from '../../../../../../utils/strings/shortUrlPath';
import { getUrlHost } from '../../../../../../utils/strings/urlHost';
import { TableDetailUrlProps } from './tableDetailUrlProps';
import React from 'react';

const TableDetailUrl: React.FC<TableDetailUrlProps> = (props) => {
    const { url } = props;

    const host = getUrlHost(url);
    const shortPath = getShortUrlPath(url);

    return (
        <>
            {host !== undefined && shortPath !== undefined && (
                <>
                    <a href={url}>.../{shortPath}</a> ({host})
                </>
            )}
            {host === undefined && url}
        </>
    );
};

export default TableDetailUrl;
