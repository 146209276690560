import { ApiError } from '../../../../../api/api';
import { AppState } from '../../../../appState';
import { getAuditRequestError } from './getAuditRequestError';
import { useSelector } from 'react-redux';

const useAuditRequestError = (): ApiError | null => {
    const auditErrorType = useSelector<AppState, ApiError | null>((state) => {
        return getAuditRequestError(state);
    });

    return auditErrorType;
};

export default useAuditRequestError;
