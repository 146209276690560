import { AppAction } from './appAction';
import { AppState } from './appState';
import { combineReducers } from 'redux';
import auditReducer from './audit/reducer';
import defaultPageReducer from './form/reducer';
import savedAuditReducer from './savedAudit/reducer';

const rootReducer = combineReducers<AppState, AppAction>({
    audit: auditReducer,
    defaultPage: defaultPageReducer,
    savedAudit: savedAuditReducer,
});

export default rootReducer;
