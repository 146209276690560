import { Box, Typography } from '@material-ui/core';
import { TableDetailNodeProps } from './tableDetailNodeProps';
import React from 'react';

const TableDetailNode: React.FC<TableDetailNodeProps> = (props) => {
    const { node } = props;

    return (
        <>
            {node !== undefined && <Typography>{node.nodeLabel}</Typography>}
            {node !== undefined && <Box>{node.snippet}</Box>}
        </>
    );
};

export default TableDetailNode;
