import { UUID } from 'io-ts-types/UUID';

export const defaultRoute = {
    url: () => '/',
    pattern: () => '/',
};

export const auditRoute = {
    url: () => '/audit',
    pattern: () => '/audit',
};

export const auditSavedResultRoute = {
    url: (uuid: UUID) => {
        const idRawNumber: string = uuid.toString();
        return '/audit/' + idRawNumber.toString();
    },
    pattern: () => '/audit/:uuid',
};
