import { AuditResultProps } from './auditResultProps';
import { Box, Container, Grid, Hidden, makeStyles, Typography, useTheme } from '@material-ui/core';
import { convertReportDeviceCategoriesToStickyMenuCategories } from '../helper/convertReportDeviceCategoryToStickyMenuCategory';
import { GlobalThemeType } from '../../../../theme/globalTheme';
import { OverviewData } from '../overview/overviewProps';
import { StickyMenuCategory } from '../stickyMenu/types/stickyMenuCategory';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Overview from '../overview';
import React, { useMemo } from 'react';
import ReportCategories from '../categories';
import ShareResult from '../shareResult';
import StickyMenu from '../stickyMenu';
import styled from 'styled-components';

const useStyles = makeStyles((theme: GlobalThemeType) => ({
    lighthouse: {
        color: theme.palette.text.secondary,
        display: 'flex',
        justifyContent: 'center',
    },
}));

interface BorderLineProps {
    readonly theme: GlobalThemeType;
}

const BorderLine = styled.div`
    border-color: ${(props: BorderLineProps) => props.theme.palette.primary.main};
    border-bottom-width: ${(props: BorderLineProps) => props.theme.spacing(1 / 4)}px;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    border-style: solid;
`;

interface OverviewBoxProps {
    readonly theme: GlobalThemeType;
}

const OverviewBox = styled(BorderLine)`
    margin-top: ${(props: OverviewBoxProps) => props.theme.spacing(6)}px;
    margin-bottom: ${(props: OverviewBoxProps) => props.theme.spacing(6)}px;
    padding-bottom: ${(props: OverviewBoxProps) => props.theme.spacing(4)}px;
`;

interface ResultBoxProps {
    readonly theme: GlobalThemeType;
}

const ResultBox = styled(BorderLine)`
    margin-top: ${(props: ResultBoxProps) => props.theme.spacing(6)}px;
    margin-bottom: ${(props: ResultBoxProps) => props.theme.spacing(4)}px;
    padding-bottom: ${(props: ResultBoxProps) => props.theme.spacing(4)}px;
`;

const AuditResult: React.FC<AuditResultProps> = (props) => {
    const { report, url, finishedAt, auditUuid } = props;

    const theme = useTheme<GlobalThemeType>();
    const classes = useStyles();

    const menuItems = useMemo<StickyMenuCategory[]>(() => {
        return convertReportDeviceCategoriesToStickyMenuCategories(report.deviceCategories);
    }, [report.deviceCategories]);

    const overview = useMemo<OverviewData>(() => {
        return {
            desktopScreenshot: report.desktopScreenshot,
            errors: report.results.failed,
            finishedAt: finishedAt,
            mobileScreenshot: report.mobileScreenshot,
            passed: report.results.passed,
            score: report.score,
            url: url,
            warnings: report.results.opportunities,
            auditUuid: auditUuid,
        };
    }, [auditUuid, finishedAt, report.desktopScreenshot, report.mobileScreenshot, report.results.failed, report.results.opportunities, report.results.passed, report.score, url]);

    return (
        <Container maxWidth={'md'}>
            <OverviewBox theme={theme}>
                <Overview {...overview} />
            </OverviewBox>
            <ResultBox theme={theme}>
                <Grid container spacing={2}>
                    <Hidden smDown={true}>
                        <Grid item md={3}>
                            <StickyMenu categories={menuItems} />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={9}>
                        <ReportCategories deviceCategories={report.deviceCategories} />
                        <ShareResult auditUuid={auditUuid} />
                        <Box mt={4} mb={2} className={classes.lighthouse}>
                            <Box mr={1}>
                                <ErrorOutlineIcon fontSize="small" />
                            </Box>
                            <Typography variant="body1" align="left">
                                Portions of this report use Lighthouse. For more information visit <a href="https://developers.google.com/web/tools/lighthouse">here</a>.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </ResultBox>
        </Container>
    );
};

export default AuditResult;
