export enum AppLanguageEnum {
    english = 'en',
}

export const getAbbreviationsByAppLanguageEnum = (appLanguage: AppLanguageEnum) => {
    switch (appLanguage) {
        case AppLanguageEnum.english:
            return 'en';
        default:
            throw new Error('unexpected AppLanguageEnum in getAbbreviationsByAppLanguageEnum');
    }
};

export const findLanguageFromUnknown = (value: unknown): AppLanguageEnum | null => {
    if (value === AppLanguageEnum.english) {
        return AppLanguageEnum.english;
    }
    return null;
};
