export enum AuditSelector {
    sendingRequestToAnalyzeStarted = 'AuditSelector_sendingRequestToAnalyzeStarted',
    sendingRequestToAnalyzeWasSuccessful = 'AuditSelector_sendingRequestToAnalyzeWasSuccessful',
    sendingRequestToAnalyzeWasFailed = 'AuditSelector_sendingRequestToAnalyzeWasFailed',

    sendingRequestsForResultCreatedAuditStarted = 'AuditSelector_sendingRequestsForResultCreatedAuditStarted',
    sendingRequestsForResultCreatedWasAuditSuccessful = 'AuditSelector_sendingRequestsForResultCreatedWasAuditSuccessful',
    sendingRequestsForResultCreatedAuditWasFailed = 'AuditSelector_sendingRequestsForResultCreatedAuditWasFailed',
    sendingRequestsForResultCreatedAuditTimeHasExpired = 'AuditSelector_sendingRequestsForResultCreatedAuditTimeHasExpired',
    sendingRequestsForResultCreatedAuditUrlHttpCodeWasForbidden = 'AuditSelector_sendingRequestsForResultCreatedAuditUrlHttpCodeWasForbidden',
    sendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorized = 'AuditSelector_sendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorized',

    loadingAuditResultWasSet = 'AuditSelector_loadingAuditResultWasSet',
    loadingAuditResultWasUnset = 'AuditSelector_loadingAuditResultWasUnset',

    progressStarted = 'AuditSelector_progressStarted',
    progressIncremented = 'AuditSelector_progressIncremented',
    progressFinished = 'AuditSelector_progressFinished',
}
