import { GlobalThemeType } from '../../../theme/globalTheme';
import { ResultPictogramProps } from './resultPictogramProps';
import { ResultType } from './ResultType';
import { useTheme } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

type ResultPictogramIcon = typeof CancelIcon | typeof CheckCircleIcon | typeof RemoveCircleIcon;

const getIconByType = (type: ResultType): ResultPictogramIcon => {
    switch (type) {
        case ResultType.error:
            return CancelIcon;
        case ResultType.passed:
            return CheckCircleIcon;
        case ResultType.warning:
            return RemoveCircleIcon;
        default:
            throw new Error('unexpected ResultPictogramType in getIconByType');
    }
};

const getColorByType = (type: ResultType, theme: GlobalThemeType): string => {
    switch (type) {
        case ResultType.error:
            return theme.palette.error.main;
        case ResultType.passed:
            return theme.palette.success.main;
        case ResultType.warning:
            return theme.palette.warning.main;
        default:
            throw new Error('unexpected ResultPictogramType in getColorByType');
    }
};

const ResultPictogram: React.FC<ResultPictogramProps> = (props) => {
    const { type } = props;

    const theme = useTheme<GlobalThemeType>();

    const Icon = getIconByType(type);

    return (
        <Icon
            style={{
                color: getColorByType(type, theme),
            }}
        />
    );
};

export default ResultPictogram;
