import { getShortUrlPath } from '../../../../../../utils/strings/shortUrlPath';
import { getUrlHost } from '../../../../../../utils/strings/urlHost';
import { TableDetailLinkProps } from './tableDetailLinkProps';
import React from 'react';

const TableDetailLink: React.FC<TableDetailLinkProps> = (props) => {
    const { entity } = props;

    const host = getUrlHost(entity.url);
    const shortPath = getShortUrlPath(entity.url);

    return (
        <>
            {host !== undefined && shortPath !== undefined && (
                <>
                    <a href={entity.url}>.../{shortPath}</a> ({host})
                </>
            )}
            {host === undefined && entity.url}
        </>
    );
};

export default TableDetailLink;
