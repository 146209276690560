import { AppState } from '../../../../appState';
import { getProgress } from './getProgress';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSetProgress from './useSetProgress';

const useProgress = (): number => {
    const value = useSelector<AppState, number>((state) => {
        return getProgress(state);
    });
    const setValue = useSetProgress();

    const inc = () => {
        let amount = 0;
        if (value < 5) {
            amount = 0.4;
        } else if (value < 20) {
            amount = 5;
        } else if (value < 50) {
            amount = 2.5;
        } else if (value < 80) {
            amount = 1.5;
        } else if (value < 95) {
            amount = 0.4;
        } else if (value < 98) {
            amount = 0.1;
        }

        setValue(Math.min(value + amount, 100));
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            inc();
        }, 500);

        return () => clearTimeout(timer);
    });

    return value;
};

export default useProgress;
