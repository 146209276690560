import { Events } from 'react-scroll/modules';
import { GlobalThemeType } from '../../../../theme/globalTheme';
import { makeStyles, MenuList, Typography, useTheme } from '@material-ui/core';
import { StickyMenuProps } from './stickyMenuProps';
import React, { useEffect, useState } from 'react';
import ScrollMenuItem from './scrollMenuItem';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    root: () => ({
        position: 'sticky',
        top: 0,
    }),
}));

interface CategoryBoxProps {
    readonly theme: GlobalThemeType;
}

const CategoryBox = styled.div`
    margin-bottom: ${(props: CategoryBoxProps) => props.theme.spacing(4)}px;
`;

const StickyMenu: React.FC<StickyMenuProps> = (props) => {
    const { categories } = props;
    const classes = useStyles();
    const theme = useTheme<GlobalThemeType>();

    const [active, setActive] = useState<string | null>(null);

    useEffect(() => {
        Events.scrollEvent.register('end', (to) => {
            // Delay setActive, it should be called after onSetActive
            setTimeout(() => setActive(to), 100);
        });
        return () => {
            Events.scrollEvent.remove('end');
        };
    }, []);

    const onSetActive = (item: string) => {
        setActive(item);
    };

    const onSetInactive = (item: string) => {
        if (active === item) {
            setActive(null);
        }
    };

    return (
        <MenuList className={classes.root}>
            {categories.map((item) => {
                return (
                    <CategoryBox theme={theme} key={item.to}>
                        <Typography variant="h6" align="left">
                            {item.name}
                        </Typography>
                        {item.items.map((subItem) => {
                            return (
                                <ScrollMenuItem
                                    key={subItem.to}
                                    to={subItem.to}
                                    active={active === subItem.to}
                                    onSetActive={() => {
                                        onSetActive(subItem.to);
                                    }}
                                    onSetInactive={() => {
                                        onSetInactive(subItem.to);
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontSize: 18,
                                        }}
                                        component="div"
                                        variant="inherit"
                                        align="right"
                                    >
                                        {subItem.name}
                                    </Typography>
                                </ScrollMenuItem>
                            );
                        })}
                    </CategoryBox>
                );
            })}
        </MenuList>
    );
};

export default StickyMenu;
