import { GlobalThemeType } from '../../../theme/globalTheme';
import i18next from 'i18next';

export enum ResultType {
    error = 'error',
    passed = 'passed',
    warning = 'warning',
}

export const translateResultType = (resultType: ResultType): string => {
    switch (resultType) {
        case ResultType.error:
            return i18next.t('pages.result.resultType.errors');
        case ResultType.warning:
            return i18next.t('pages.result.resultType.warning');
        case ResultType.passed:
            return i18next.t('pages.result.resultType.passed');
        default:
            throw new Error('Unexpected ResultType in translateResultType');
    }
};

export const getColorByResultType = (resultType: ResultType, theme: GlobalThemeType): string => {
    switch (resultType) {
        case ResultType.error:
            return theme.palette.error.main;
        case ResultType.warning:
            return theme.palette.warning.main;
        case ResultType.passed:
            return theme.palette.success.main;
        default:
            throw new Error('Unexpected ResultType in getColorByResultType');
    }
};

export const getResultTypeByPercent = (percent: number): ResultType => {
    if (percent < 50) {
        return ResultType.error;
    }
    if (percent >= 90) {
        return ResultType.passed;
    }
    return ResultType.warning;
};
