import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import useProgress from '../../../../store/audit/hook/auditRequest/useProgress/useProgress';

const useStyles = makeStyles(() => ({
    linearProgress: {
        height: 16,
        width: 400,
        margin: '0 auto',
    },
}));

const ProgressBar: React.FC<{}> = () => {
    const value = useProgress();
    const { t } = useTranslation();
    const classes = useStyles();

    let text = t('pages.result.progress.finishingAudit');
    if (value < 5) {
        text = t('pages.result.progress.validatingUrl');
    } else if (value < 10) {
        text = t('pages.result.progress.startingAudit');
    } else if (value < 30) {
        text = t('pages.result.progress.performanceMeasuring');
    } else if (value < 55) {
        text = t('pages.result.progress.validatingAccessibility');
    } else if (value < 65) {
        text = t('pages.result.progress.bestPracticesCheck');
    } else if (value < 75) {
        text = t('pages.result.progress.seoBasicControl');
    } else if (value < 85) {
        text = t('pages.result.progress.checkingHtml');
    } else if (value < 90) {
        text = t('pages.result.progress.generatingScreenshots');
    } else if (value < 95) {
        text = t('pages.result.progress.auditForMobileScreen');
    } else if (value < 97) {
        text = t('pages.result.progress.dataSizeControl');
    } else if (value < 98) {
        text = t('pages.result.progress.scoreCalculating');
    }

    return (
        <Box>
            <LinearProgress variant="determinate" value={value} className={classes.linearProgress} />
            <Typography align="center">{text}</Typography>
        </Box>
    );
};

export default ProgressBar;
