import { ApiError, generalErrors } from '../../api/api';
import { AuditSelector } from './selector';
import { Report } from '../../api/endpointAuditGet';
import { UUID } from 'io-ts-types/UUID';

export const sendingRequestToAnalyzeStarted = (url: string) => {
    return {
        type: AuditSelector.sendingRequestToAnalyzeStarted,
        url,
    } as const;
};
export type SendingRequestToAnalyzeStartedAction = ReturnType<typeof sendingRequestToAnalyzeStarted>;

export const sendingRequestToAnalyzeWasSuccessful = (auditUuid: UUID) => {
    return {
        type: AuditSelector.sendingRequestToAnalyzeWasSuccessful,
        auditUuid: auditUuid,
    } as const;
};
export type SendingRequestToAnalyzeWasSuccessfulAction = ReturnType<typeof sendingRequestToAnalyzeWasSuccessful>;

export const sendingRequestToAnalyzeWasFailed = (error: ApiError) => {
    return {
        type: AuditSelector.sendingRequestToAnalyzeWasFailed,
        error,
    } as const;
};
export type SendingRequestToAnalyzeWasFailedAction = ReturnType<typeof sendingRequestToAnalyzeWasFailed>;

export const sendingRequestsForResultCreatedAuditStarted = (auditUuid: UUID) => {
    return {
        type: AuditSelector.sendingRequestsForResultCreatedAuditStarted,
        auditUuid: auditUuid,
    } as const;
};
export type SendingRequestsForResultCreatedAuditStartedAction = ReturnType<typeof sendingRequestsForResultCreatedAuditStarted>;

export const sendingRequestsForResultCreatedWasAuditSuccessful = (auditUuid: UUID, url: string, isReachable: boolean, finishedAt: string, report: Report) => {
    return {
        type: AuditSelector.sendingRequestsForResultCreatedWasAuditSuccessful,
        auditUuid: auditUuid,
        url,
        isReachable,
        finishedAt,
        report,
    } as const;
};
export type SendingRequestsForResultCreatedWasAuditSuccessfulAction = ReturnType<typeof sendingRequestsForResultCreatedWasAuditSuccessful>;

export const sendingRequestsForResultCreatedAuditWasFailed = (error?: ApiError) => {
    return {
        type: AuditSelector.sendingRequestsForResultCreatedAuditWasFailed,
        error: error ?? generalErrors.failed,
    } as const;
};
export type SendingRequestsForResultCreatedAuditWasFailedAction = ReturnType<typeof sendingRequestsForResultCreatedAuditWasFailed>;

export const sendingRequestsForResultCreatedAuditTimeHasExpired = () => {
    return {
        type: AuditSelector.sendingRequestsForResultCreatedAuditTimeHasExpired,
    } as const;
};
export type SendingRequestsForResultCreatedAuditTimeHasExpiredAction = ReturnType<typeof sendingRequestsForResultCreatedAuditTimeHasExpired>;

export const sendingRequestsForResultCreatedAuditUrlHttpCodeWasForbidden = () => {
    return {
        type: AuditSelector.sendingRequestsForResultCreatedAuditUrlHttpCodeWasForbidden,
    } as const;
};
export type SendingRequestsForResultCreatedAuditUrlHttpCodeWasForbiddenAction = ReturnType<typeof sendingRequestsForResultCreatedAuditUrlHttpCodeWasForbidden>;

export const sendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorized = () => {
    return {
        type: AuditSelector.sendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorized,
    } as const;
};
export type SendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorizedAction = ReturnType<typeof sendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorized>;

export const loadingAuditResultWasSet = () => {
    return {
        type: AuditSelector.loadingAuditResultWasSet,
    } as const;
};
export type LoadingAuditResultWasSetAction = ReturnType<typeof loadingAuditResultWasSet>;

export const loadingAuditResultWasUnset = () => {
    return {
        type: AuditSelector.loadingAuditResultWasUnset,
    } as const;
};
export type LoadingAuditResultWasUnsetAction = ReturnType<typeof loadingAuditResultWasUnset>;

export const progressStart = () => {
    return {
        type: AuditSelector.progressStarted,
    } as const;
};
export type ProgressStartAction = ReturnType<typeof progressStart>;

export const progressIncrement = (value: number) => {
    return {
        type: AuditSelector.progressIncremented,
        value,
    } as const;
};
export type ProgressIncrementAction = ReturnType<typeof progressIncrement>;

export const progressFinish = () => {
    return {
        type: AuditSelector.progressFinished,
    } as const;
};
export type ProgressFinishAction = ReturnType<typeof progressFinish>;

export type AuditAction =
    | SendingRequestToAnalyzeStartedAction
    | SendingRequestToAnalyzeWasSuccessfulAction
    | SendingRequestToAnalyzeWasFailedAction
    | SendingRequestsForResultCreatedAuditTimeHasExpiredAction
    | SendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorizedAction
    | SendingRequestsForResultCreatedAuditUrlHttpCodeWasForbiddenAction
    | SendingRequestsForResultCreatedAuditWasFailedAction
    | SendingRequestsForResultCreatedWasAuditSuccessfulAction
    | SendingRequestsForResultCreatedAuditStartedAction
    | LoadingAuditResultWasSetAction
    | LoadingAuditResultWasUnsetAction
    | ProgressStartAction
    | ProgressIncrementAction
    | ProgressFinishAction;
