import { progressIncrement } from '../../../action';
import { useCallback } from 'react';
import useDispatchTyped from '../../../../hook/useDispatchTyped/useDispatchTyped';

const useSetProgress = () => {
    const dispatch = useDispatchTyped();

    const setProgress = useCallback(
        async (value: number) => {
            await dispatch(progressIncrement(value));
        },
        [dispatch],
    );

    return setProgress;
};

export default useSetProgress;
