import { AuditSource } from './header/AuditSource';
import { GlobalThemeType } from '../../../../../theme/globalTheme';
import { numberToNonNegativeInteger } from '../../../../../utils/numbers/numberToInteger';
import { ReportCategoryProps } from './reportCategoryProps';
import { useTheme } from '@material-ui/core';
import CategoryHeader from './header';
import React from 'react';
import ReportAudits from './audits';
import styled from 'styled-components';

interface BoxWrapProps {
    readonly theme: GlobalThemeType;
}

const BoxWrap = styled.div`
    margin-bottom: ${(props: BoxWrapProps) => props.theme.spacing(12)}px;
`;

interface AuditsWrapProps {
    readonly theme: GlobalThemeType;
}

const AuditsWrap = styled.div`
    margin-top: ${(props: AuditsWrapProps) => props.theme.spacing(4)}px;
`;

const ReportCategory: React.FC<ReportCategoryProps> = (props) => {
    const { category, deviceType } = props;

    const theme = useTheme<GlobalThemeType>();

    return (
        <BoxWrap theme={theme}>
            <CategoryHeader
                counts={{
                    error: numberToNonNegativeInteger(category.audits.failed.length),
                    warning: numberToNonNegativeInteger(category.audits.opportunities.length),
                    passed: numberToNonNegativeInteger(category.audits.passed.length),
                }}
                score={category.score}
                title={category.name}
                source={category.source as AuditSource}
                deviceType={deviceType}
                failed={category.failed === true}
            />
            <AuditsWrap theme={theme}>
                <ReportAudits audits={category.audits} />
            </AuditsWrap>
        </BoxWrap>
    );
};

export default ReportCategory;
