import { Box, createStyles, Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Typography, withStyles } from '@material-ui/core';
import { ReportAuditProps } from './ReportAuditProps';
import { ResultPictogramTextType } from '../../../../../../../../pictogram/resultWithText/ResultPictogramTextType';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpportunityDetail from '../../../../../../opportunityDetail';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import ResultPictogramWithText from '../../../../../../../../pictogram/resultWithText';
import TableDetail from '../../../../../../tableDetail';

const Accordion = withStyles({
    root: {
        border: 'none',
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        border: 'none',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() =>
    createStyles({
        root: {
            display: 'block',
        },
    }),
)(MuiAccordionDetails);

const ReportAudit: React.FC<ReportAuditProps> = (props) => {
    const { audit, resultType } = props;

    const [expanded, setExpanded] = React.useState<boolean>(false);

    const handleChange = () => {
        setExpanded(!expanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange} variant={'outlined'}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <ResultPictogramWithText text={audit.title} textType={ResultPictogramTextType.bold} type={resultType} fontSize={17} />
                    {audit.displayValue !== undefined && <Typography align="right">{audit.displayValue}</Typography>}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <ReactMarkdown
                    components={{
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        a: ({ node, ...props }) => <a {...props} target="_blank" />,
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        p: ({ node, ...props }) => <Typography {...props} variant={'body2'} align="left" />,
                    }}
                >
                    {audit.description}
                </ReactMarkdown>
                {audit.detail !== undefined && audit.detail.type === 'table' && expanded && (
                    <Box mt={2}>
                        <TableDetail data={audit.detail} />
                    </Box>
                )}
                {audit.detail !== undefined && audit.detail.type === 'opportunity' && expanded && (
                    <Box mt={2}>
                        <OpportunityDetail data={audit.detail} />
                    </Box>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default ReportAudit;
