import { EnumValues } from 'enum-values';

export const getEnumKeys = (enumParam: any): string[] => EnumValues.getNames(enumParam); /* eslint-disable-line @typescript-eslint/no-explicit-any */ // not exists Enum like type for parameter

export const getEnumValues = <T extends number | string>(enumParam: any): T[] => EnumValues.getValues(enumParam); /* eslint-disable-line @typescript-eslint/no-explicit-any */ // not exists Enum like type for parameter

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEnumValidValue = <T extends number | string>(enumParam: any, value: T): boolean => {
    const values = getEnumValues(enumParam);
    return values.some((v): boolean => {
        return value === v;
    });
};
