import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { UrlSource } from '../../../store/form/state';
import { useTranslation } from 'react-i18next';
import BackgroundDesktop from '../../../images/rocket-desktop.svg';
import BackgroundMobile from '../../../images/rocket-mobile.svg';
import DefaultForm from './form';
import FooterDefault from '../../footerDefault';
import Header from '../../header';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import theme, { GlobalThemeType } from '../../../theme/globalTheme';
import useSetUrlConfirmedAction from '../../../store/form/hook/urlConfirmed/useSetUrlConfirmedAction/useSetUrlConfirmedAction';

interface BackgroundContentWrapProps {
    readonly isSmallLayout: boolean;
    readonly theme: GlobalThemeType;
}

const BackgroundContentWrap = styled.div`
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${(props: BackgroundContentWrapProps) => (props.isSmallLayout ? BackgroundMobile : BackgroundDesktop)}');
    display: flex;
    min-height: calc(100vh - 64px - 72px);
    justify-content: center;
    margin-top: ${(props: BackgroundContentWrapProps) => props.theme.spacing(9)}px;
    max-width: 100vw;
    flex-direction: column;
`;

interface ContentWrapProps {
    readonly isSmallLayout: boolean;
    readonly theme: GlobalThemeType;
}

const ContentWrap = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: ${(props: ContentWrapProps) => {
        if (props.isSmallLayout) {
            return theme.spacing(4) + 'px';
        }
        return 'auto';
    }};
    margin-right: ${(props: ContentWrapProps) => {
        if (props.isSmallLayout) {
            return theme.spacing(4) + 'px';
        }
        return 'auto';
    }};
    max-width: ${(props: ContentWrapProps) => {
        if (props.isSmallLayout) {
            return 'none';
        }
        return theme.spacing(154) + 'px';
    }};
    padding-bottom: ${(props: ContentWrapProps) => {
        if (props.isSmallLayout) {
            return 0;
        }
        return theme.spacing(10) + 'px';
    }};
`;

interface ContentWrapBoxProps {
    readonly isSmallLayout: boolean;
    readonly theme: GlobalThemeType;
}

const ContentWrapBox = styled.div`
    display: flex;
    width: 100%;
    margin-left: ${(props: ContentWrapBoxProps) => {
        if (props.isSmallLayout) {
            return 0;
        }
        return theme.spacing(0) + 'px';
    }};
    margin-right: ${(props: ContentWrapBoxProps) => {
        if (props.isSmallLayout) {
            return 0;
        }
        return theme.spacing(0) + 'px';
    }};
`;

const DefaultPage: React.FC<{}> = () => {
    const { t } = useTranslation();
    const theme = useTheme<GlobalThemeType>();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const setUrlConfirmed = useSetUrlConfirmedAction();

    const onSubmit = useCallback(
        async (url: string) => {
            await setUrlConfirmed(url, UrlSource.defaultPage);
        },
        [setUrlConfirmed],
    );

    return (
        <>
            <Header defaultUrl={undefined} onEnteredUrl={null} />
            <BackgroundContentWrap theme={theme} isSmallLayout={isSmallLayout}>
                <ContentWrapBox theme={theme} isSmallLayout={isSmallLayout}>
                    <ContentWrap theme={theme} isSmallLayout={isSmallLayout}>
                        <Typography
                            style={{
                                maxWidth: isSmallLayout ? theme.spacing(50) : theme.spacing(95),
                                fontSize: isSmallLayout ? theme.spacing(5) : theme.spacing(7),
                            }}
                            variant={'h1'}
                        >
                            {t('pages.default.title')}
                        </Typography>
                        <Typography
                            style={{
                                maxWidth: isSmallLayout ? theme.spacing(32) : theme.spacing(95),
                                color: '#737373',
                                textAlign: 'left',
                                fontSize: 32,
                                marginTop: isSmallLayout ? theme.spacing(3) : theme.spacing(3),
                                marginBottom: isSmallLayout ? theme.spacing(9) : theme.spacing(10),
                            }}
                            variant={'h2'}
                        >
                            {t('pages.default.subtitle')}
                        </Typography>
                        <DefaultForm onEnteredUrl={onSubmit} />
                    </ContentWrap>
                </ContentWrapBox>
                <FooterDefault />
            </BackgroundContentWrap>
        </>
    );
};

export default DefaultPage;
