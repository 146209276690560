import { GlobalThemeType } from '../../../theme/globalTheme';
import { ResultPictogramTextType } from './ResultPictogramTextType';
import { ResultPictogramWithTextProps } from './resultPictogramWithTextProps';
import { Typography, useTheme } from '@material-ui/core';
import React from 'react';
import ResultPictogram from '../result';
import styled from 'styled-components';

const BoxWrap = styled.div`
    display: flex;
    align-items: center;
`;

const ResultPictogramWithText: React.FC<ResultPictogramWithTextProps> = (props) => {
    const { textType, type, text, fontSize: fontSizeFromProps } = props;

    const theme = useTheme<GlobalThemeType>();

    const fontSize = fontSizeFromProps === undefined ? 20 : fontSizeFromProps;

    return (
        <BoxWrap>
            <ResultPictogram type={type} />
            <Typography
                variant={'body1'}
                align="left"
                style={{
                    ...(textType === ResultPictogramTextType.normal
                        ? {}
                        : {
                              fontWeight: 'bold',
                          }),
                    marginLeft: theme.spacing(1),
                    fontSize: fontSize,
                }}
            >
                {text}
            </Typography>
        </BoxWrap>
    );
};

export default ResultPictogramWithText;
