import { SavedAuditErrorType } from './types/savedAuditErrorType';
import { SavedAuditRequestResult } from './state';
import { SavedAuditSelector } from './selector';
import { UUID } from 'io-ts-types/UUID';

export const loadingAuditStarted = (auditUuid: UUID) => {
    return {
        type: SavedAuditSelector.loadingAuditStarted,
        auditUuid: auditUuid,
    } as const;
};
export type LoadingAuditStartedAction = ReturnType<typeof loadingAuditStarted>;

export const loadingAuditWasSuccessful = (savedAuditRequestResult: SavedAuditRequestResult) => {
    return {
        type: SavedAuditSelector.loadingAuditWasSuccessful,
        savedAuditRequestResult,
    } as const;
};
export type LoadingAuditWasSuccessfulAction = ReturnType<typeof loadingAuditWasSuccessful>;

export const loadingAuditFailed = (errorType: SavedAuditErrorType) => {
    return {
        type: SavedAuditSelector.loadingAuditFailed,
        errorType,
    } as const;
};
export type LoadingAuditFailedAction = ReturnType<typeof loadingAuditFailed>;

export type SavedAuditAction = LoadingAuditStartedAction | LoadingAuditWasSuccessfulAction | LoadingAuditFailedAction;
