import { getShortUrlPath } from '../../../../../../utils/strings/shortUrlPath';
import { getUrlHost } from '../../../../../../utils/strings/urlHost';
import { TableDetailSourceLocationProps } from './tableDetailSourceLocationProps';
import React from 'react';

const TableDetailSourceLocation: React.FC<TableDetailSourceLocationProps> = (props) => {
    const { entity } = props;

    const host = getUrlHost(entity.url);
    const shortPath = getShortUrlPath(entity.url);

    return (
        <>
            {host !== undefined && shortPath !== undefined && (
                <>
                    <a href={entity.url}>
                        .../{shortPath}:{entity.line}:{entity.column}
                    </a>{' '}
                    ({host})
                </>
            )}
            {host === undefined && entity.url}
        </>
    );
};

export default TableDetailSourceLocation;
