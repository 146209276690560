import { urlConfirmed } from '../../../action';
import { UrlSource } from '../../../state';
import { useCallback } from 'react';
import useDispatchTyped from '../../../../hook/useDispatchTyped/useDispatchTyped';

const useSetUrlConfirmedAction = () => {
    const dispatch = useDispatchTyped();

    const setUrlConfirmed = useCallback(
        async (url: string, urlSource: UrlSource) => {
            await dispatch(urlConfirmed(url, urlSource));
        },
        [dispatch],
    );

    return setUrlConfirmed;
};

export default useSetUrlConfirmedAction;
