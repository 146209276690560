import { GlobalThemeType } from '../../theme/globalTheme';
import { Link, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactComponent as LogoFull } from '../../images/logo-full.svg';
import { ReactComponent as LogoM } from '../../images/trigama-logo-m.svg';
import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

const useStyles = makeStyles<GlobalThemeType>(() => ({
    logo: () => ({
        '& path': {
            fill: '#737373',
        },
    }),
}));

interface BoxWrapProps {
    readonly isSmallLayout: boolean;
    readonly theme: GlobalThemeType;
}

const BoxWrap = styled.div`
    margin-left: ${(props: BoxWrapProps) => {
        return props.theme.spacing(props.isSmallLayout ? 2 : 10);
    }}px;
    margin-right: ${(props: BoxWrapProps) => {
        return props.theme.spacing(props.isSmallLayout ? 2 : 10);
    }}px;
    position: ${(props: BoxWrapProps) => {
        if (props.isSmallLayout) {
            return 'relative';
        }
        return 'absolute';
    }};
    bottom: ${(props) => {
        return props.isSmallLayout ? 'auto' : props.theme.spacing(3) + 'px';
    }};
    margin-top: ${(props) => {
        return props.isSmallLayout ? props.theme.spacing(10) : 0;
    }}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: ${(props: BoxWrapProps) => {
        if (props.isSmallLayout) {
            return 'calc(100% - ' + props.theme.spacing(2) * 2 + 'px)';
        }
        return 'calc(100% - ' + props.theme.spacing(10) * 2 + 'px)';
    }};
`;

const Copyright = styled.div`
    display: flex;
    text-align: left;
`;

interface LogoProps {
    readonly isSmallLayout: boolean;
    readonly theme: GlobalThemeType;
}

const Logo = styled.div`
    display: flex;
    width: ${(props: LogoProps) => {
        if (props.isSmallLayout) {
            return props.theme.spacing(6);
        }
        return props.theme.spacing(30);
    }}px;
    margin-left: ${(props: LogoProps) => {
        if (props.isSmallLayout) {
            return props.theme.spacing(2) + 'px';
        }
        return 0;
    }};
`;

const FooterDefault: React.FC<{}> = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme<GlobalThemeType>();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <BoxWrap theme={theme} isSmallLayout={isSmallLayout}>
            <Copyright>
                <Typography
                    style={{
                        fontSize: 18,
                        color: '#737373',
                    }}
                >
                    {t('footer.allRightsReservedPart1', { year: new Date().getFullYear() })}
                    {" "}
                    <Link target="_blank" href="https://trigama.eu">{t('footer.allRightsReservedPart2')}</Link>
                    {isSmallLayout && <br />}
                    {!isSmallLayout && ', '}
                    {t('footer.allRightsReservedPart3')}
                </Typography>
            </Copyright>
            <Logo theme={theme} isSmallLayout={isSmallLayout}>
                {!isSmallLayout && <LogoFull className={classes.logo} />}
                {isSmallLayout && <LogoM className={classes.logo} />}
            </Logo>
        </BoxWrap>
    );
};

export default FooterDefault;
