import { ReportAuditListProps } from './ReportAuditListProps';
import React from 'react';
import ReportAudit from './audit';
import styled from 'styled-components';

const BoxWrap = styled.div``;

const ReportAuditList: React.FC<ReportAuditListProps> = (props) => {
    const { auditList, type } = props;

    return (
        <BoxWrap>
            {auditList.map((audit, index) => {
                return <ReportAudit audit={audit} resultType={type} key={index} />;
            })}
        </BoxWrap>
    );
};

export default ReportAuditList;
