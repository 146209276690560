import { AppAction } from '../appAction';
import { AuditReducerState } from './state';
import { AuditSelector } from './selector';
import { generalErrors } from '../../api/api';
import { Reducer } from 'redux';

const getInitialState = (): AuditReducerState => {
    return {
        auditRequest: {
            error: null,
            auditUuid: undefined,
            enteredUrl: undefined,
            loadingAuditResult: false,
            result: undefined,
            progress: 0,
        },
    };
};

const auditReducer: Reducer<AuditReducerState, AppAction> = (state = getInitialState(), action) => {
    switch (action.type) {
        case AuditSelector.sendingRequestToAnalyzeStarted:
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    enteredUrl: action.url,
                    auditUuid: undefined,
                    error: null,
                    result: undefined,
                },
            };
        case AuditSelector.sendingRequestToAnalyzeWasFailed:
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    error: action.error,
                },
            };
        case AuditSelector.sendingRequestToAnalyzeWasSuccessful: {
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    auditUuid: action.auditUuid,
                },
            };
        }
        case AuditSelector.sendingRequestsForResultCreatedWasAuditSuccessful:
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    result: {
                        url: action.url,
                        isReachable: action.isReachable,
                        finishedAt: action.finishedAt,
                        report: action.report,
                    },
                },
            };
        case AuditSelector.sendingRequestsForResultCreatedAuditWasFailed: {
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    error: action.error,
                },
            };
        }
        case AuditSelector.sendingRequestsForResultCreatedAuditTimeHasExpired: {
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    error: generalErrors.timeout,
                },
            };
        }
        case AuditSelector.sendingRequestsForResultCreatedAuditUrlHttpCodeWasForbidden: {
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    error: generalErrors.forbidden,
                },
            };
        }
        case AuditSelector.sendingRequestsForResultCreatedAuditUrlHttpCodeWasUnauthorized: {
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    error: generalErrors.unauthorized,
                },
            };
        }
        case AuditSelector.loadingAuditResultWasSet: {
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    loadingAuditResult: true,
                },
            };
        }
        case AuditSelector.loadingAuditResultWasUnset: {
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    loadingAuditResult: false,
                },
            };
        }
        case AuditSelector.progressStarted: {
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    progress: 0,
                },
            };
        }
        case AuditSelector.progressIncremented: {
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    progress: action.value,
                },
            };
        }
        case AuditSelector.progressFinished: {
            return {
                ...state,
                auditRequest: {
                    ...state.auditRequest,
                    progress: 100,
                },
            };
        }
        default:
            return state;
    }
};

export default auditReducer;
