import { DeviceCategory } from '../../../../api/endpointAuditGet';
import { Element } from 'react-scroll';
import { ReportCategoriesProps } from './ReportCategoriesProps';
import React from 'react';
import ReportCategory from './category';
import styled from 'styled-components';

const BoxWrap = styled.div``;

const ReportCategories: React.FC<ReportCategoriesProps> = (props) => {
    const { deviceCategories } = props;

    return (
        <BoxWrap>
            {deviceCategories.map((deviceCategory) => {
                return deviceCategory.categories.map((category) => {
                    return (
                        <Element name={`${deviceCategory.id}-${category.id}`} key={`${deviceCategory.id}-${category.id}`}>
                            <ReportCategory category={category} deviceType={deviceCategory.id as DeviceCategory} />
                        </Element>
                    );
                });
            })}
        </BoxWrap>
    );
};

export default ReportCategories;
