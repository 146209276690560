import { ColorPartial, PaletteOptions, SimplePaletteColorOptions, TypeText } from '@material-ui/core/styles/createPalette';
import { createMuiTheme } from '@material-ui/core';
import { ThemeOptions } from '@material-ui/core';

const paletteSuccess: SimplePaletteColorOptions = {
    light: 'rgba(23, 169, 185, 0.1)',
    main: '#17a9b9',
    dark: '#17a9b9',
    // contrastText: '#2adeb0',
};

const paletteWarning: SimplePaletteColorOptions = {
    light: 'rgba(255, 170, 0, 0.1)',
    main: '#ffaa00',
    dark: '#ffaa00',
    // contrastText: '#f5a46b',
};

const paletteError: SimplePaletteColorOptions = {
    light: 'rgba(255, 83, 0, 0.06)',
    main: '#ff5300',
    dark: '#ff5300',
    // contrastText: '#f73d87',
};

const palettePrimary: SimplePaletteColorOptions = {
    light: '#17a9b9',
    main: '#17a9b9',
    dark: '#17a9b9',
    contrastText: '#ffffff',
};

const paletteSecondary: SimplePaletteColorOptions = {
    light: '#27c49c',
    main: '#27c49c',
    dark: '#27c49c',
    //     contrastText: '#774fdc',
};

const paletteGrey: ColorPartial = {
    ...createMuiTheme().palette.grey,
    50: '#f3f1f6',
};

const paletteText: Partial<TypeText> = {
    primary: '#737373',
    secondary: '#737373',
};

const palette: PaletteOptions = {
    success: paletteSuccess,
    warning: paletteWarning,
    error: paletteError,
    primary: palettePrimary,
    secondary: paletteSecondary,
    grey: paletteGrey,
    text: paletteText,
};

const themeOptions: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1900,
        },
    },
    typography: {
        allVariants: {
            color: paletteText.primary,
        },
        h1: {
            fontSize: 54,
            fontWeight: 900,
            textAlign: 'left',
            color: palettePrimary.main,
        },
        h2: {
            fontSize: 40,
            letterSpacing: 0,
            color: '#000000',
        },
        h3: {
            color: palettePrimary.main,
            display: 'flex',
            fontSize: 35,
            fontWeight: 700,
            letterSpacing: 0,
        },
        h4: {
            fontSize: 18,
            letterSpacing: 0,
            color: 'rgba(0,0,0,0.64);',
        },
        h6: {
            fontSize: 20,
            fontWeight: 500,
        },
    },
    palette: palette,
};

const theme = createMuiTheme(themeOptions);

export type GlobalThemeType = typeof theme;

export default theme;
