import { Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, IconButton, Link, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { getCookieMoreInfoUrl } from './config';
import { GlobalThemeType } from '../../theme/globalTheme';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Cookies from 'js-cookie';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

const cookieConsentName = 'cookie-consent';
const cookieConsentAnalyticsName = 'cookie-consent-analytics';
const cookieConsentMarketingName = 'cookie-consent-marketing';

const useStyles = makeStyles<GlobalThemeType>((theme) => ({
    root: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        zIndex: theme.zIndex.appBar,
        padding: theme.spacing(1, 1),
        backgroundColor: '#E6E6E6',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    buttonsCover: {
        display: 'flex',
        whiteSpace: 'nowrap',
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            marginLeft: 0,
        },
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const CookieConsent: React.FC<{}> = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme<GlobalThemeType>();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const [isModalOpen, setModalIsOpen] = React.useState<boolean>(false);

    const [isCookiesAnalyticsChecked, setCookiesAnalyticsIsChecked] = React.useState<boolean>(true);
    const [isCookiesMarketingChecked, setCookiesMarketingIsChecked] = React.useState<boolean>(true);
    // eslint-disable-next-line
    const [isFormSubmitted, setFormIsSubmitted] = React.useState<boolean>(false);

    const handleModalClose = () => {
        setModalIsOpen(false);
    };

    const handleModalOpen = () => {
        setModalIsOpen(true);
    };

    const handleAllowCookies = (allowAll?: boolean) => {
        Cookies.set(cookieConsentName, '1');
        if (allowAll || isCookiesAnalyticsChecked) {
            Cookies.set(cookieConsentAnalyticsName, '1');
        }
        if (allowAll || isCookiesMarketingChecked) {
            Cookies.set(cookieConsentMarketingName, '1');
        }
        handleModalClose();
        setIsOpen(false);
        setFormIsSubmitted(true);
    };

    return (
        <>
            {Cookies.get(cookieConsentName) || !isOpen ? (
                <>
                    <Helmet>
                        {/*{(Cookies.get(cookieConsentAnalyticsName) || (isCookiesAnalyticsChecked && isFormSubmitted)) && <script>alert('analytics scripts')</script>}*/}
                        {/*{(Cookies.get(cookieConsentMarketingName) || (isCookiesMarketingChecked && isFormSubmitted)) && <script>alert('marketing scripts')</script>}*/}
                    </Helmet>
                </>
            ) : (
                <>
                    <div className={classes.root}>
                        <Container maxWidth="lg" className={classes.container}>
                            <Typography
                                style={{
                                    fontSize: isSmallLayout ? 24 : 17,
                                    color: '#737373',
                                }}
                            >
                                {t('cookieConsent.description')}{' '}
                                <Link href={getCookieMoreInfoUrl()} target="_blank">
                                    {t('cookieConsent.cookieInformation')}
                                </Link>
                                .{' '}
                                <Link href="#" onClick={handleModalOpen}>
                                    {t('cookieConsent.cookieSettings')}
                                </Link>
                            </Typography>
                            <div className={classes.buttonsCover}>
                                <Button
                                    onClick={handleModalOpen}
                                    color="primary"
                                    variant="outlined"
                                    style={{
                                        fontWeight: 500,
                                        letterSpacing: isSmallLayout ? 'normal' : 2.6,
                                        backgroundColor: '#ffffff',
                                    }}
                                    fullWidth={isSmallLayout}
                                    size={isSmallLayout ? 'large' : 'medium'}
                                >
                                    {t('cookieConsent.btn.cookieSettings')}
                                </Button>
                                <Button
                                    onClick={() => handleAllowCookies(true)}
                                    color="primary"
                                    variant="contained"
                                    className={classes.button}
                                    style={{
                                        fontWeight: 500,
                                        letterSpacing: isSmallLayout ? 'normal' : 2.6,
                                    }}
                                    fullWidth={isSmallLayout}
                                    size={isSmallLayout ? 'large' : 'medium'}
                                >
                                    {t('cookieConsent.btn.allowAllCookies')}
                                </Button>
                            </div>
                        </Container>
                    </div>
                    <Dialog open={isModalOpen} onClose={handleModalClose}>
                        <DialogTitle>
                            {t('cookieConsent.modal.title')}
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleModalClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Typography>{t('cookieConsent.modal.description')}</Typography>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox disabled checked={true} />} label={t('cookieConsent.modal.functionalCookies.title')} />
                                    <Typography>{t('cookieConsent.modal.functionalCookies.description')}</Typography>
                                    <FormControlLabel
                                        control={<Checkbox checked={isCookiesAnalyticsChecked} onChange={(e) => setCookiesAnalyticsIsChecked(e.target.checked)} />}
                                        label={t('cookieConsent.modal.analyticalCookies.title')}
                                    />
                                    <Typography>{t('cookieConsent.modal.analyticalCookies.description')}</Typography>
                                    <FormControlLabel
                                        control={<Checkbox checked={isCookiesMarketingChecked} onChange={(e) => setCookiesMarketingIsChecked(e.target.checked)} />}
                                        label={t('cookieConsent.modal.marketingCookies.title')}
                                    />
                                    <Typography>{t('cookieConsent.modal.marketingCookies.description')}</Typography>
                                </FormGroup>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => handleAllowCookies()}
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                style={{
                                    fontWeight: 500,
                                    letterSpacing: isSmallLayout ? 'normal' : 2.6,
                                }}
                                fullWidth={isSmallLayout}
                                size={isSmallLayout ? 'large' : 'medium'}
                            >
                                {t('cookieConsent.modal.btn.allowSelection')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
};

export default CookieConsent;
