import { ajax } from 'rxjs/ajax';
import { AppAction } from '../appAction';
import { AppState } from '../appState';
import { auditGetResultSchema, endpointAuditGet } from '../../api/endpointAuditGet';
import { catchError, map, mergeMap } from 'rxjs/internal/operators';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EpicDependencies } from '../epicDependencies';
import { isLeft } from 'fp-ts/Either';
import { loadingAuditFailed, LoadingAuditFailedAction, LoadingAuditStartedAction, loadingAuditWasSuccessful, LoadingAuditWasSuccessfulAction } from './action';
import { SavedAuditErrorType } from './types/savedAuditErrorType';
import { SavedAuditRequestResult } from './state';
import { SavedAuditSelector } from './selector';
import { UUID } from 'io-ts-types/UUID';
import { ZonedDateTime } from '@js-joda/core';

const loadingAuditStartedMapToLoadingAuditResultWasSetEpic: Epic<
    LoadingAuditStartedAction,
    // @ts-ignore
    LoadingAuditWasSuccessfulAction | LoadingAuditFailedAction,
    AppState,
    EpicDependencies
> = (action$) => {
    return action$.pipe(
        ofType(SavedAuditSelector.loadingAuditStarted),
        mergeMap((action) => {
            const { auditUuid } = action;

            return ajax.get(endpointAuditGet(auditUuid)).pipe(
                map((response) => {
                    const validationResult = auditGetResultSchema.safeParse(response.response.data);

                    if (validationResult.success !== true) {
                        return loadingAuditFailed(SavedAuditErrorType.error);
                    }

                    const data = validationResult.data;

                    const optionAuditUuidString = data.uuid;
                    const optionAuditUuid = UUID.decode(optionAuditUuidString);
                    if (isLeft(optionAuditUuid)) {
                        return loadingAuditFailed(SavedAuditErrorType.error);
                    }
                    const auditUuid = optionAuditUuid.right;

                    const url = data.url;
                    if (url === undefined) {
                        return loadingAuditFailed(SavedAuditErrorType.error);
                    }

                    const isReachable = data.isReachable;
                    if (isReachable !== true) {
                        return loadingAuditFailed(SavedAuditErrorType.error);
                    }

                    const report = data.report;
                    if (report === undefined) {
                        return loadingAuditFailed(SavedAuditErrorType.error);
                    }

                    const finishedAtString = data.finishedAt;
                    if (finishedAtString === undefined) {
                        return loadingAuditFailed(SavedAuditErrorType.error);
                    }

                    const savedAuditRequestResult: SavedAuditRequestResult = {
                        uuid: auditUuid,
                        url: url,
                        status: data.status,
                        isReachable: isReachable,
                        report: report,
                        finishedAt: ZonedDateTime.parse(finishedAtString),
                    };

                    return loadingAuditWasSuccessful(savedAuditRequestResult);
                }),
                catchError(() => {
                    return [loadingAuditFailed(SavedAuditErrorType.error)];
                }),
            );
        }),
    );
};

export const savedAuditEpics = combineEpics<AppAction, AppAction, AppState, EpicDependencies>(
    // @ts-ignore
    loadingAuditStartedMapToLoadingAuditResultWasSetEpic,
);
