import { AuditErrorType } from '../store/audit/types/auditErrorType';
import { CreatingAuditErrorType } from '../store/audit/types/creatingAuditErrorType';
import { TFunction } from 'i18next';

export type ApiError = {
    type: AuditErrorType | CreatingAuditErrorType;
    translation: (t: TFunction) => string;
};

export type ApiErrors = Record<string, ApiError>;

export const generalErrors: ApiErrors = {
    failed: {
        type: AuditErrorType.fail,
        translation: (t: TFunction) => t('pages.result.error.failed'),
    },
    timeout: {
        type: AuditErrorType.timeHasExpired,
        translation: (t: TFunction) => t('pages.result.error.timeHasExpired'),
    },
    unauthorized: {
        type: AuditErrorType.unauthorized,
        translation: (t: TFunction) => t('pages.result.error.unauthorized'),
    },
    forbidden: {
        type: AuditErrorType.forbidden,
        translation: (t: TFunction) => t('pages.result.error.forbidden'),
    },
};
