import { CategoryHeaderProps } from './categoryHeaderProps';
import { CircleChartSize } from '../../../../../chart/circle/CircleChartSize';
import { GlobalThemeType } from '../../../../../../theme/globalTheme';
import { Hidden, Tooltip, Typography, useTheme } from '@material-ui/core';
import { RoundType } from '../../../../../chart/linear/roundType';
import { useTranslation } from 'react-i18next';
import CategoryHeaderAuditsCounts from './auditsCounts';
import CategoryHeaderTitle from './title';
import CircleChart from '../../../../../chart/circle';
import LinearChart from '../../../../../chart/linear';
import React from 'react';
import styled from 'styled-components';

const BoxWrap = styled.div`
    display: flex;
    justify-content: space-between;
    border-style: solid;
    border-width: 0 0 0 0;
`;

const TitleAuditsCountsWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

interface ScoreWrapProps {
    readonly theme: GlobalThemeType;
}

const ScoreWrap = styled.div`
    display: flex;
    margin-right: ${(props: ScoreWrapProps) => props.theme.spacing(4)}px;
`;

interface AuditsCountsWrapProps {
    readonly theme: GlobalThemeType;
}

const AuditsCountsWrap = styled.div`
    display: flex;
    margin-top: ${(props: AuditsCountsWrapProps) => props.theme.spacing(1)}px;
`;

interface LinearChartWrapProps {
    readonly theme: GlobalThemeType;
}

const LinearChartWrap = styled.div`
    margin-top: ${(props: LinearChartWrapProps) => props.theme.spacing(3)}px;
`;

const CategoryHeader: React.FC<CategoryHeaderProps> = (props) => {
    const { counts, title, score, source, deviceType, failed } = props;
    const { t } = useTranslation();

    const theme = useTheme<GlobalThemeType>();

    const tooltips = {
        lighthouse: t('pages.result.category.header.tooltipLighthouse'),
        w3c: t('pages.result.category.header.tooltipW3c'),
    };

    return (
        <BoxWrap>
            <TitleAuditsCountsWrap>
                <CategoryHeaderTitle text={title} deviceCategory={deviceType} failed={failed} />
                {failed === true && <Typography align={'left'}>{t('pages.result.overview.failed')}</Typography>}
                {failed === false && (
                    <LinearChartWrap theme={theme}>
                        <LinearChart percent={score} roundType={RoundType.oneDecimalPlace} />
                    </LinearChartWrap>
                )}
                {failed === false && (
                    <AuditsCountsWrap theme={theme}>
                        <CategoryHeaderAuditsCounts counts={counts} />
                    </AuditsCountsWrap>
                )}
            </TitleAuditsCountsWrap>
            {failed === false && (
                <Hidden smDown={true}>
                    <ScoreWrap theme={theme}>
                        <Tooltip title={tooltips[source]}>
                            <CircleChart percent={score} size={CircleChartSize.small} />
                        </Tooltip>
                    </ScoreWrap>
                </Hidden>
            )}
        </BoxWrap>
    );
};

export default CategoryHeader;
