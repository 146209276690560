import { loadingAuditStarted } from '../../../action';
import { useCallback } from 'react';
import { UUID } from 'io-ts-types/UUID';
import useDispatchTyped from '../../../../hook/useDispatchTyped/useDispatchTyped';

const useSetLoadingAuditStartedAction = () => {
    const dispatch = useDispatchTyped();

    const setLoadingAuditStarted = useCallback(
        async (uuid: UUID) => {
            await dispatch(loadingAuditStarted(uuid));
        },
        [dispatch],
    );

    return setLoadingAuditStarted;
};

export default useSetLoadingAuditStartedAction;
